<template>
    <div>
        <div class="revenue-table__head">
            <h1>Revenue</h1>
            <div class="revenue-table__menu">
                <!-- <el-input placeholder="Search" v-model="searchByText" class="revenue-list__search input-with-select" style="width:400px">
                    <el-select v-model="searchByType" slot="prepend" placeholder="Select">
                        <el-option label="Merchant" value="merchant"></el-option>
                        <el-option label="Category" value="category"></el-option>
                        <el-option label="Vehicle" value="vehicle"></el-option>
                    </el-select>
                    <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input> -->

                <div>
                    <el-button-group>
                        <router-link to="/revenue/add">
                            <el-button type="primary" icon="el-icon-plus">New record</el-button>
                        </router-link>
                            <!-- <el-button type="primary" icon="el-icon-delete" @click="handleMultipleDelete"></el-button> -->
                    </el-button-group>
                </div>
            </div>
        </div>

        <el-dialog
            title="Details"
            :visible.sync="dialogVisible"
            width="50%"
            top="60px"
            bottom="0px"
            class="panel">
            <h1 class="detail__head">{{ revenue.type }}</h1>
            <div>{{ revenue.vehicle }} vehicle</div>
            <h2>INR {{ revenue.amount }}</h2>
            
            <div class="detail__date detail__field">{{ revenue.date }}</div>

            <div class="detail__field">
                <span class="detail__field-label">category :</span>
                {{ revenue.category }}
            </div>

            <div class="detail__field">
                <span class="detail__field-label">merchant :</span>
                {{ revenue.merchant }}
            </div>

            <div class="detail__field">
                <span class="detail__field-label">reference :</span>
                {{ revenue.reference }}
            </div>
            
            <div class="detail__field">
                <span class="detail__field-label">description :</span>
                    {{ revenue.description }}
            </div>

            <div class="detail__field" style="border-top:1px dashed #DDD">
                <span class="detail__field-label">attachments :</span>
            </div>

            <div class="detail__receipts">
                <ul>
                    <li class="detail__receipts-item" v-for="receipt in attachments" :key="receipt.url">
                        <img :src="receipt.url" @click="handlePreview(receipt)">
                    </li>
                </ul>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleEdit(null, revenue)" type="success">Edit <i class="el-icon-edit"></i></el-button>
                <el-button @click="handleDelete(null, revenue)" type="danger">Delete <i class="el-icon-delete"></i></el-button>
                <el-button @click="dialogVisible = false">Close <i class="el-icon-close"></i></el-button>
            </span>
        </el-dialog>

        <el-card class="box-card">
            <el-table 
                :data="revenues" 
                :row-class-name="tableRowClassName" class="revenue-table" @selection-change="handleSelectionChange">
                    
                <!-- <el-table-column type="selection" width="55">
                </el-table-column> -->

                <el-table-column
                    sortable
                    prop="date"
                    label="Date"
                    >
                    <template slot-scope="scope">
                        <i class="el-icon-time" style="color:#3b76e2"></i>
                        <span style="margin-left: 10px">{{ scope.row.date|formatDate }}</span>
                    </template>
                </el-table-column>

                <el-table-column
                    sortable
                    prop="vehicle"
                    label="Vehicle">
                </el-table-column>

                <!-- <el-table-column
                    sortable
                    prop="merchant"
                    label="Merchant">
                </el-table-column> -->

                <el-table-column
                    sortable
                    prop="amount"
                    label="Amount">
                    <template slot-scope="scope">
                        INR<span style="margin-left: 5px">{{ scope.row.amount }}</span>
                    </template>
                </el-table-column>
                
                <el-table-column
                    class-name="uppercase"
                    prop="category"
                    sortable
                    label="Category">
                </el-table-column>
                
                <el-table-column
                    class-name="uppercase"
                    prop="type"
                    sortable
                    label="Type">
                </el-table-column>
                
                <!-- <el-table-column
                    prop="reference"
                    sortable
                    label="Reference">
                </el-table-column> -->

                <el-table-column align="right"  width="250">
                    <template slot-scope="scope">
                        <el-button
                        size="mini"
                        @click="handleView(scope.$index, scope.row)">View</el-button>

                        <el-button
                        size="mini"
                        @click="handleEdit(scope.$index, scope.row)">Edit</el-button>

                        <el-button
                        size="mini"
                        type="danger"
                        @click="handleDelete(scope.$index, scope.row)">Delete</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <!-- <div class="block revenue-table__pagination">
            <el-pagination layout="prev, pager, next" :total="50">
            </el-pagination>
        </div> -->
        
        <el-dialog
            :title="previewImage.name"
            :visible.sync="previewDialogShow"
            width="50%">
            <div class="receipt__preview">
                <img :src="previewImage.url">
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="previewDialogShow = false">Cancel</el-button>
            </span>
        </el-dialog>
    </div>
</template>


<script>
    import firebase from 'firebase/app';
    import router from '@/router.js'

    export default {
        name: 'RevenueList',
        data() {
            return {
                searchByType: 'Vehicle',
                searchByText: '',
                multipleSelection: [],
                dialogVisible: false,
                revenue: {},
                previewDialogShow : false,
                previewImage : '',
                attachments: [],
                computedAttachments: []
            }
        },
        props: {
            revenues: Array
        },
        methods: {
            handleClose() {

            },
            tableRowClassName({row, rowIndex}) {
                if (row.category === 'Service') {
                    return 'warning-row';
                } else if (row.category === 'Fuel') {
                    return 'success-row';
                }

                return '';
            },
            handleEdit(index, transaction) {
                router.push({ name: 'revenueEdit', params: { id: transaction.id } })

            },
            handleDelete(index, transaction) {
                this.$store.dispatch('$_revenue/deleteRevenue', transaction.id)
                this.dialogVisible = false
            },
            handleMultipleDelete() {
                console.log('in multiple delete', this.multipleSelection);
            },
            handleSelectionChange(val) {
                this.multipleSelection = val;
            },
            handleView(index, transaction) {
                this.dialogVisible = true
                this.revenue       = transaction
                this.attachments   = []

                var storage = firebase.storage()
                var images = transaction.receipts
                var image
                var self = this
                var storageRef = storage.ref()
                
                for (var i = 0, len = images.length; i < len; i++) {
                    image = images[i]
                    storageRef.child(image.url).getDownloadURL().then((url) => {
                        self.attachments.push({
                            url: url
                        })
                    });
                }
            },
            handlePreview(image) {
                this.previewImage      = image
                this.previewDialogShow = true
            }
        },
        created() {

        },
        mounted() {
            
        }
    }
</script>


<style lang="scss">
.box-card {
    margin:2rem;
    .el-card__body {
        padding: 0;
    }
}

.revenue-table {
    // box-shadow: 0 2px 2px 0 rgba(0,0,0,.1);
    background-color:#FFF;
    box-sizing: border-box;
    
    .cell {
        color:#222;
    }

}

.el-table th {
    &:hover > .cell {
        color:#fc0;
        transition:color .3s;
    }
}

.revenue-table__head {
    display: flex;
    flex-direction: column;
    padding:2rem;
}

.revenue-table__menu {
    display: flex;
    justify-content: space-between;
}

.revenue-table__pagination {
    display: flex;
    justify-content: center;
}

.uppercase {
    text-transform: uppercase;
}

.el-table .warning-row {
    background: rgb(240, 232, 217);
}

.el-table .success-row {
    background: #f0f9eb;
}

.el-table {
    tr {
        height:5rem;
    }
}

.revenue-list__search .el-input {
    width:140px !important;
}

.panel {

    .el-dialog {
        z-index:2000;
        top:0;
        right:0;
        position:fixed;
        background-color:#FFF;
        height:calc(100vh - 60px);
    }
    
}

.detail__head {
    text-transform: uppercase;
    border-bottom:3px solid #1abc9c;
    padding-bottom:1rem;
}

.detail__date {
    border-bottom:1px dashed #DDD;
}

.detail__field {
    padding-bottom:1rem;
    padding-top:1rem;
}

.detail__field-label {
    text-transform: uppercase;
    font-weight: bold;

}

.detail__receipts ul {
    display: flex;
    align-items: center;
    
}

.detail__receipts-item {
    width:120px;
    padding:5px;
    cursor: pointer;

    img {
        width:120px;
    }
}
</style>
