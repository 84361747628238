<template>
    <div id="app">
        <component :is="layout">
            <router-view/>
        </component>
        <div v-show="loading" style="position:fixed; top:0; left:0; width:100vw; height:100vh; background-color:rgb(0,0,0,.4); display:flex; align-items:center; justify-content: center; color:#FFF;font-weight:bold;">
            loading
        </div>
    </div>                  
</template>

<script>
    import { mapGetters } from 'vuex'
    // import vehiclesStore from '@/store/vehicles.js'

    const default_layout = 'admin'

    export default {
        data() {
            return {
                loading: false
            }
        },
        computed: {
            layout() {
                return (this.$route.meta.layout || default_layout) + '-layout'
            },
            // ...mapGetters({
            //     vehicles: '$_vehicles/vehicles'
            // })
        },
        created() {
            var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            const STORE_KEY = '$_vehicless'
            
            if (!(STORE_KEY in this.$store._modules.root._children)) {
                // this.$store.registerModule(STORE_KEY, vehiclesStore)
            }

        },
        mounted() {
            // this.$store.dispatch('$_vehicless/getAll')
        }
    }
</script>



<style lang="scss">
#app {
    font-family: 'Avenir', Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    
}

#nav {

  a {
    font-weight: bold;
    color: #82b8ee;
    &.router-link-exact-active {
        color: #42b983;
    }
  }
}

.is-fullwidth {
    margin:0 !important;
    width:100% !important;
    max-width:100% !important;
}

.is-aside-wrap {
    background-color:#333;
}

.is-always-shadow {
    -webkit-box-shadow:0 0 12px 0 rgba(146, 146, 146, 0.1);
    box-shadow:0 0 12px 0 rgba(146, 146, 146, 0.1);
}

.navbar-top {
    z-index:9999;
}

.d-aside {
    top:60px;

}

.d-content-right {
    margin-left: 16.66667%;
    margin-top:60px;
}

@media only screen and (max-width: 600px) {
    .d-aside {
        top:unset;
    }

    .d-content-right {
        margin-left: unset;
        margin-top: unset;
    }
}


</style>
