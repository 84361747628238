import Vue from 'vue'
import Router from 'vue-router'
import Revenue from './views/Revenue.vue'
import RevenueAdd from "./modules/revenue/add";
import Media from './views/Media.vue'
import Login from '@/components/Auth/Login.vue'
import Logout from '@/components/Auth/Logout.vue'
import Profile from './views/Profile.vue'
import Vehicles from '@/modules/vehicles'
import VehiclesEdit from '@/modules/vehicles/edit'
import Iframe from './views/Iframe'

import firebase from 'firebase'

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'dashboard',
            meta: {
                layout: 'admin',
                requiresAuth: true
            },
            component: Iframe,
        },
        {
            path: '/revenue',
            name: 'revenue',
            component: Revenue,
            meta: {
                layout: 'admin',
                requiresAuth: true
            }
        },{
            path: '/revenue/add',
            name: 'revenueAdd',
            component: RevenueAdd,
            meta: {
                layout: 'admin',
                requiresAuth: true
            }
        },{
            path: '/revenue/edit/:id',
            name: 'revenueEdit',
            component: RevenueAdd,
            meta: {
                layout: 'admin',
                requiresAuth: true
            }
        },
        {
            path: '/media',
            name: 'media',
            component: Media,
            meta: {
                layout: 'admin',
                requiresAuth: true
            }
        },
        {
            path: '/maps',
            name: 'maps',
            component: Iframe,
            meta: {
                layout: 'admin',
                requiresAuth: true
            }
        },
        {
            path: '/vehicles',
            name: 'vehicles',
            component: Vehicles,
            meta: {
                layout: 'admin',
                requiresAuth: true
            }
        },{
            path: '/vehicles/edit/:id',
            name: 'vehiclesEdit',
            component: VehiclesEdit,
            meta: {
                layout: 'admin',
                requiresAuth: true
            }
        },
        {
            path: '/reports',
            name: 'reports',
            component: Iframe,
            meta: {
                layout: 'admin',
                requiresAuth: true
            }
        },{
            path: '/cstatus',
            name: 'cstatus',
            component: Iframe,
            meta: {
                layout: 'admin',
                requiresAuth: true
            }
        },{
            path: '/geofences',
            name: 'geofences',
            component: Iframe,
            meta: {
                layout: 'admin',
                requiresAuth: true
            }
        },
        {
            path: '/profile',
            name: 'profile',
            component: Iframe,
            meta: {
                layout: 'admin',
                requiresAuth: true
            }
        },
        {
            path: '/login',
            name: 'Login',
            component: Login,
            meta: {
                layout: 'authentication'
            }
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
            meta: {
                layout: 'admin',
                requiresAuth: true
            }
        }
    ]
});

router.beforeEach((to, from, next) => {
    const currentUser = firebase.auth().currentUser
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

    if (requiresAuth && !currentUser) {
        next('login')
    } else if (!requiresAuth && currentUser) {
        next('/')
    } else {
        next()
    }
})

export default router