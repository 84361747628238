<template>
    <div class="navbar" id="topNavBar">
        <el-menu :router="true" :default-active="activeIndex" mode="horizontal" >
            <el-menu-item index="0"><img src="/images/zuaxis_logo.png"> <span class="logo-txt"> </span> </el-menu-item>
        </el-menu>

        <el-menu :default-active="activeIndex" mode="horizontal">
            
            <!-- <el-menu-item index="1">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                        <i class="el-icon-message"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item index="11">Action 1Action 1</el-dropdown-item>
                        <el-dropdown-item>Action 2</el-dropdown-item>
                        <el-dropdown-item>Action 3</el-dropdown-item>
                        <el-dropdown-item>Action 4</el-dropdown-item>
                        <el-dropdown-item>Action 5</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-menu-item> -->

            <!-- 

            <el-menu-item index="2">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                        <i class="el-icon-bell"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>Action 1Action 1</el-dropdown-item>
                        <el-dropdown-item>Action 2</el-dropdown-item>
                        <el-dropdown-item>Action 3</el-dropdown-item>
                        <el-dropdown-item>Action 4</el-dropdown-item>
                        <el-dropdown-item>Action 5</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-menu-item>

            <el-menu-item index="3">
                <el-dropdown trigger="click">
                    <span class="el-dropdown-link">
                        <i class="el-icon-setting"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item>Action 1Action 1</el-dropdown-item>
                        <el-dropdown-item>Action 2</el-dropdown-item>
                        <el-dropdown-item>Action 3</el-dropdown-item>
                        <el-dropdown-item>Action 4</el-dropdown-item>
                        <el-dropdown-item>Action 5</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-menu-item> -->
            <el-menu-item index="2">
                <UserInfo/>
            </el-menu-item>
        </el-menu>

        

    </div>
    
</template>


<script>

import UserInfo from './UserInfo'

export default {
    name: "NavBar",
    data() {
        return {
            activeIndex: '1',
            activeIndex2: '1'
        };
    },
    methods: {
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        }
    },
    components:{
        UserInfo
    }
}
</script>


<style scoped>
.navbar-wrap {
    box-shadow: 0 0 10px #DDD;
    max-width:100% !important;
    width:100% !important;
    background-color:#FFF;
}

.navbar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color:#FFF;
    box-shadow: 0 0 10px #BBB;
    z-index:999999;
}
.el-menu--horizontal {
    border-bottom:none;
}

.el-menu--horizontal > .el-menu-item.is-active {
    border-bottom:none;
}
.el-menu--horizontal > .el-menu-item.is-active:active { 
    border-bottom:none;
}

img {
    height:60%;
}

.logo-txt {
    color:#222;
    font-weight: bold;
    font-size:2rem;
}
</style>

