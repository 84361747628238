<template>
    <vehicleList :vehicles="vehicles"/>
</template>


<script>
import { mapGetters } from 'vuex'
import store from './_store'
import VehicleList from './_components/VehicleList'

export default {
    name: 'VehicleListModule',
    components: {
        VehicleList
    },
    computed: {
        ...mapGetters({
            vehicles: '$_vehicles/vehicles'
        })
    },
    created() {
        const STORE_KEY = '$_vehicles'
        if (!(STORE_KEY in this.$store._modules.root._children)) {
            this.$store.registerModule(STORE_KEY, store);
        }
    },
    mounted() {
        window.xx = this.$store
        this.$store.dispatch('$_vehicles/fetchVehicles');
    }

}
</script>
