<template>
    <div>
        <vehiclesEdit/>
    </div>
  
</template>

<script>
import { mapGetters } from 'vuex'
import store from './_store'
import VehiclesEdit from './_components/VehiclesEdit'

export default {
    name: 'VehiclesEditModule',
    components: {
        VehiclesEdit,
    },
    computed: {
        ...mapGetters({
            vehicles: '$_vehicles/vehicles'
        })
    },
    created() {
        const STORE_KEY = '$_vehicles'
        if (!(STORE_KEY in this.$store._modules.root._children)) {
            this.$store.registerModule(STORE_KEY, store);
        }
    },
    mounted() {
        const id = this.$route.params.id
        this.$store.dispatch('$_vehicles/fetchVehicles', id);
    }

}
</script>
