<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex'
import store from './_store'

export default {
    name: 'DocumentModule',
    computed: {
        ...mapGetters({
            documents: '$_documents/documents'
        })
    },
    created() {
        const STORE_KEY = '$_documents'
        if (!(STORE_KEY in this.$store._modules.root._children)) {
            this.$store.registerModule(STORE_KEY, store);
        }
    },
    mounted() {
        // action call "getRevenues" method
        // this.$store.dispatch('$_documents/getDocuments');
    }

}
</script>
