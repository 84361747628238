<template>
    <div>Logout</div>
</template>

<script>
import firebase from 'firebase'
import router from '@/router.js'

export default {
    name: 'Logout',
    data() {
        return {}
    },
    methods: {
        logout() {
            var self = this
            firebase.auth().signOut().then(function() {
                router.push({path: '/login'})

            }, function(error) {
                console.error('Sign Out Error', error);
            });
        }
    },
    created() {
        this.logout()
    }
}
</script>