const VEHICLES_FETCHED = (state, vehicles) => {
    console.log('vehicles:: ', vehicles);
    state.vehicles = vehicles;
    
}

const VEHICLE_FETCHED_BY_ID = (state, id) => {
    var vehicles = state.vehicles

    for (var i = 0, len = vehicles.length; i < len; i++) {
        if (vehicles[i]._id === id) {
            state.vehicle = vehicles[i]
            break
        }
    }
    
}

const VEHICLE_UPDATED = (state, vehicle) => {
    var vehicles = state.vehicles

    for (var i = 0, len = vehicles.length; i < len; i++) {
        if (vehicles[i]._id === vehicle._id) {
            vehicles[i] = vehicle
            console.log('updated in mutations');
            break
        }
    }
}

export default {
    VEHICLES_FETCHED,
    VEHICLE_FETCHED_BY_ID,
    VEHICLE_UPDATED
}