import api from '../_api'
import { Notification } from 'element-ui'


const getRevenues = (context) => {
    return api.fetchRevenues()
        .then((response) => {
            context.commit('REVENUES_UPDATED', response)
        })
        .catch((error) => {
            console.error(error)
        })
}

const getRevenueById = (context) => {
    api.fetchRevenueById
        .then((response) => {
            context.commit('REVENUE_UPDATED', response)
        })
        .catch((error) => {
            console.error(error)
        })
}

const addRevenue = (context, revenue) => {
    api.addRevenue(revenue)
        .then((response) => {
            context.commit('REVENUE_ADDED', response)
            Notification.success({
                title: 'Success',
                message: 'Revenue added successfully',
                offset: 100
            })
        })
        .catch((error) => {
            console.error(error);
        });
    
}

const deleteRevenue = (context, transactionId) => {
    api.deleteRevenue(transactionId)
        .then(() => {
            context.commit('REVENUE_DELETED', transactionId)
            Notification.success({
                title: 'Success',
                message: 'Revenue deleted successfully',
                offset: 100
            })
        })
        .catch((error) => {
            console.error(error);
        });
}

const updateRevenue = (context, transaction) => {
    var self = this
    api.updateRevenue(transaction)
        .then((response) => {
            Notification.success({
                title: 'Success',
                message: 'Revenue updated successfully',
                offset: 100
            })
            context.commit('REVENUE_UPDATED', response);

        })
        .catch((error) => {
            console.error(error);
            Notification.error({
                title: 'Failure',
                message: 'Error updating revenue',
                offset: 100
            })
        });
}


export default {
    getRevenues,
    getRevenueById,
    addRevenue,
    deleteRevenue,
    updateRevenue
};