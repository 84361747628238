<template>
    <div>
        <slot />
    </div>    
</template>


<script>
import Login from '@/components/Auth/Login'
import Logout from '@/components/Auth/Logout'

export default {
    components: {
        Login: Login,
        Logout: Logout
    }
}

</script>
