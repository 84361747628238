import $$ from '@/configs/const.js'

const fetchVehicles = function() {
    return $$.http.get( '/customer/handshake' )
}

const updateVehicleById = function (fleet_id, fleetData) {
    return $$.http.put( '/customer/fleet/'+ fleet_id, fleetData)
}

export default {
    fetchVehicles,
    updateVehicleById
}