<template>
    <el-row class="media__wrap">
        <el-col :span="24">
            <div class="media__head">
                <div class="media__head-left">
                    <div >
                        <i data-v-11e7682f="" class="el-icon-picture"></i>
                    </div>
                </div>

                <div class="media__nav">
                    <ul class="media__nav-items">
                        <li class="media__nav-item"><a class="is-active" href="/media">All</a></li>
                        <li class="media__nav-item"><a href="/media/images">Images</a></li>
                        <li class="media__nav-item"><a href="/media/documents">Documents</a></li>
                        <li class="media__nav-item"><a href="/media/videos">Videos</a></li>
                        <li class="media__nav-item"><a href="/media/audios">Audios</a></li>
                    </ul>
                </div>

                <div class="media__stats"></div>
            </div>
        </el-col>


        <el-col :span="24">
            <div class="media__head">
                <div class="media__head-left">

                </div>
                <div class="media__nav-size media__head-left" style="padding-right:1rem;">
                    <div class="media__nav-size-small">
                        <i data-v-11e7682f="" class="el-icon-picture" style="font-size:10px;"></i>
                    </div>
                    <div class="block" style="width:200px;">
                        <el-slider v-model="itemSize" v-on:change="resizeImagePreview" :step="1" show-stops :min="0" :max="9" :show-tooltip="false"></el-slider>
                    </div>
                    <div class="media__nav-size-large">
                        <i data-v-11e7682f="" class="el-icon-picture"></i>
                    </div>
                </div>
            </div>
        </el-col>


        <el-col :span="24">
            <div class="media-upload">
                <el-upload
                    class="media-list__upload"
                    drag
                    action="https://jsonplaceholder.typicode.com/posts/"
                    :on-preview="handlePreview"
                    :on-remove="handleRemove"
                    :file-list="fileList"
                    multiple>
                    <i class="el-icon-upload"></i>
                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div>
                </el-upload>
            </div>
            <div class="media-list">
                <div class="media-list__item" v-for="file in files" v-bind:style="{backgroundImage: 'url('+ file.src + ')'}"></div>
            </div>
        </el-col>
    </el-row>
</template>


<script>
export default {
    name: 'media',
    data() {
        return {
            itemSize: 4,
            fileList: [],
            f: '/images/thumbs/1.jpg',
            files: [{
                src: '/images/thumbs/1.jpg'
            },{
                src: '/images/thumbs/2.jpg'
            },{
                src: '/images/thumbs/3.jpg'
            },{
                src: '/images/thumbs/4.jpg'
            },{
                src: '/images/thumbs/5.jpg'
            },{
                src: '/images/thumbs/6.jpg'
            },{
                src: '/images/thumbs/7.jpg'
            },{
                src: '/images/thumbs/8.jpg'
            },{
                src: '/images/thumbs/9.jpg'
            },{
                src: '/images/thumbs/10.jpg'
            },{
                src: '/images/thumbs/11.jpg'
            },{
                src: '/images/thumbs/12.jpg'
            },{
                src: '/images/thumbs/13.jpg'
            },{
                src: '/images/thumbs/14.jpg'
            },{
                src: '/images/thumbs/15.jpg'
            }]
        }
    },
    methods: {
        handlePreview() {

        },
        handleRemove() {

        },
        resizeImagePreview() {
            var items = document.querySelectorAll( '.media-list__item' )
            var size = this.itemSize
            Array.prototype.slice.call( items ).forEach(element => {
                element.style.width = 'calc(100% /'+ (10 - size) +' - 2rem)'
                element.style.height = 'calc((100vw - 20vw) / '+ (10 - size) +' - 2rem)'
            });
            
        }
    }
}
</script>


<style lang="scss">
@import '../../assets/scss/colors';

.media__head {
    border:1px solid #EEE;
    height:50px;
    background-color:#FFF;
    margin:1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.media__head-left {
    border-right: 1px solid #DDD;
    width:100px;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    
}

.media__nav {
    height:100%;
}

.media__stats {
    width:200px;
    height: 100%;
    border-left:1px solid #EEE;
}

.media__nav-items {
    display: flex;
    flex-direction: row;
    margin:0;
    height:100%;
}

.media__nav-item {
    height:100%;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0 1rem;
        color:$BELIZE_HOLE;
        height:100%;

        &:hover {
            color:$ALIZARIN;
        }

        &.is-active {
            color:$PUMPKIN;
            border-bottom:2px solid $ALIZARIN;
        }

    }
}

.media__nav-size {
    display: flex;
    flex-direction: row;
    width: 200px;
}

.media__nav-size-small {
    margin-right:1rem;
}

.media__nav-size-large  {
    margin-left:1rem;
}

.media-list, .media-upload {
    margin: 1rem;
}

.media-list__upload {
    // width:100%;
}

.el-upload {
    width:100% !important;
}

.el-upload-dragger {
    width:100% !important;
    height:50vh;
}

.el-upload__tip {
    text-align: center;
}

.media-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.media-list__item {
    width:calc(100% / 5 - 2rem);
    max-width: 100%;
    height: calc((100vw - 20vw) / 5 - 2rem);
    background-color: #FFF;
    margin:1rem;
    background-size: cover;
    background-position: center;
    cursor:pointer;
}
</style>
