const REVENUES_UPDATED = (state, revenues) => {
    state.revenues = revenues;
    
}
  
const REVENUE_ADDED = (state, revenue) => {
    state.revenues.push(revenue)
}

const REVENUE_UPDATED = (state, revenue) => {
    for (var i = 0, len = state.revenues.length; i < len; i++) {
        if (state.revenues[i].id == revenue.id) {
            state.revenues[i] = revenue
            return
        }
    } 
}

const REVENUE_DELETED = (state, revenueId) => {
    for (var i = 0, len = state.revenues.length; i < len; i++) {
        if (state.revenues[i].id == revenueId) {
            state.revenues.splice(i, 1)
            return
        }
    }
}

export default {
    REVENUES_UPDATED,
    REVENUE_ADDED,
    REVENUE_DELETED,
    REVENUE_UPDATED
};