<template>
    <div class="container">
        <div class="revenue-table__head">
            <h2>Add Transaction</h2>
        </div>
        <el-row :span="24">
            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div class="revenue-add">
                    <el-form ref="transactionForm" :model="transaction" label-width="130px">
                        <el-form-item label="Transaction Type"
                            prop="type"
                            :rules="[{ required: true, message: 'Select a transaction type'}]">
                                <el-select v-model="transaction.type" clearable placeholder="Select">
                                    <el-option label="Income" value="income"></el-option>
                                    <el-option label="Expense" value="expense"></el-option>
                                </el-select>
                        </el-form-item>

                        <el-form-item label="Date" :rules="[{ required: true, message: 'Date is required'}]">
                            <el-date-picker
                                v-model="transaction.date"
                                type="date"
                                placeholder="Pick a day"
                                :picker-options="pickerOptions1">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="Vehicle"
                            prop="vehicle"
                            :rules="[{ required: true, message: 'Vehicle is required'}]">
                            <el-select v-model="transaction.vehicle" clearable placeholder="Select">
                                <el-option label="common" value="common"></el-option>
                                <el-option
                                    v-for="item in vehicles"
                                    :key="item.reg_no"
                                    :label="item.reg_no"
                                    :value="item.reg_no">
                                </el-option>
                            </el-select>
                        </el-form-item>
                            

                        <el-form-item label="Merchant">
                            <el-select v-model="transaction.merchant" clearable placeholder="Select">
                                <el-option label="merchant1" value="merchant1"></el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="Category"
                            prop="category"
                            :rules="[{ required: true, message: 'Category is required'}]">
                                <el-select v-model="transaction.category" clearable placeholder="Select">
                                    <el-option label="general" value="general">General</el-option>
                                    <el-option label="service" value="service">Service</el-option>
                                    <el-option label="tyre" value="tyre">Tyre</el-option>
                                    <el-option label="fuel" value="fuel">Fuel</el-option>
                                    <el-option label="breakdown" value="breakdown">Breakdown</el-option>
                                    <el-option label="others" value="others">Others</el-option>
                                </el-select>
                        </el-form-item>

                        <el-form-item label="Amount"
                            prop="amount"
                            :rules="customRules.amount"
                            class="is-required"
                            >
                            <el-input type="number" placeholder="Please input" v-model.number="transaction.amount">
                                <template slot="prepend">INR</template>
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Description">
                            <el-input 
                                type="textarea"
                                :autosize="{ minRows: 2, maxRows: 4}"
                                placeholder="Please input"
                                v-model="transaction.description">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="Reference">
                            <el-input v-model="transaction.reference"></el-input>
                        </el-form-item>
                        
                        <div class="field">
                            <el-button type="primary" @click="save('transactionForm')" v-loading.fullscreen.lock="fullscreenLoading">Save</el-button>
                        </div>
                    </el-form>
                </div>
            </el-col>

            <el-col :xs="24" :sm="24" :md="24" :lg="12" :xl="12">
                <div class="revenue-add">
                    <el-upload
                        class="upload-demo"
                        action=""
                        drag
                        accept="image/png, image/jpeg"
                        :auto-upload="false"
                        :on-change="handleChange"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        list-type="picture"
                        multiple>
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                        <div class="el-upload__tip" slot="tip">jpg/png files with a size less than 500kb</div>
                    </el-upload>
                    <el-dialog
                        title="Receipt"
                        :visible.sync="dialogVisible"
                        width="50%">
                        <div class="receipt__preview">
                            <img :src="receiptPreview">
                        </div>
                        <span slot="footer" class="dialog-footer">
                            <el-button @click="dialogVisible = false">Cancel</el-button>
                        </span>
                    </el-dialog>
                </div>
            </el-col>

        </el-row>
        <!-- columns end -->
    </div>
</template>


<script>

import firebase from '@/firebaseConfig.js'
import { mapGetters } from 'vuex'

var generatePushID = (function() {
    // Modeled after base64 web-safe chars, but ordered by ASCII.
    var PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

    // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
    var lastPushTime = 0;

    // We generate 72-bits of randomness which get turned into 12 characters and appended to the
    // timestamp to prevent collisions with other clients.  We store the last characters we
    // generated because in the event of a collision, we'll use those same characters except
    // "incremented" by one.
    var lastRandChars = [];

    return function() {
        var now = new Date().getTime();
        var duplicateTime = (now === lastPushTime);
        lastPushTime = now;

        var timeStampChars = new Array(8);
        for (var i = 7; i >= 0; i--) {
            timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
            // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
            now = Math.floor(now / 64);
        }
        if (now !== 0) throw new Error('We should have converted the entire timestamp.');

        var id = timeStampChars.join('');

        if (!duplicateTime) {
            for (i = 0; i < 12; i++) {
                lastRandChars[i] = Math.floor(Math.random() * 64);
            }
        } else {
            // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
            for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
                lastRandChars[i] = 0;
            }
            lastRandChars[i]++;
        }
        for (i = 0; i < 12; i++) {
            id += PUSH_CHARS.charAt(lastRandChars[i]);
        }
        if (id.length != 20) throw new Error('Length should be 20.');

        return id;
    };
})();

export default {
    name: 'revenueAdd',
    data() {
        var checkAmount = (rule, value, callback) => {
        
            if (!value) {
                return callback(new Error('Please input the amount'));
            }

            if (/^\d+(\.\d{1,2})?$/.test(value)) {
                callback()
            } else {
                callback(new Error('Please input in amount format'))
            }
        };

        return {
            transaction: {
                type: '',
                date: '',
                merchant: '',
                vehicle: '',
                category: '',
                amount: '',
                description: '',
                reference: '',
                receipts: []
            },
            customRules: {
                amount: [{ validator: checkAmount, trigger: 'blur' }]
            },
            fileList: [],
            fullscreenLoading: false,
            dialogVisible: false,
            receiptPreview: '',
            pickerOptions1: {
                shortcuts: [{
                    text: 'Today',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    }
                }, {
                    text: 'Yesterday',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    }
                }, {
                    text: 'A week ago',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    }
                }]
            },
        }
    },
    computed: {
        ...mapGetters({
                vehicles: '$_vehicles/vehicles'
        })
    },
    methods: {
        save(formName) {
            var self = this
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    self.fullscreenLoading = true

                    if (self.fileList.length > 0) {
                        self.uploadImageToFirestorage(formName)

                    } else {
                        self.handleSave(formName)

                    }
                } else {
                    console.log('error submit!!');
                    return false;
                }
            })

        },
        handleSave(formName) {
            if (this.isEdit) {
                this.$store.dispatch('$_revenue/updateRevenue', this.transaction)

            } else {
                this.$store.dispatch('$_revenue/addRevenue', this.transaction)
                this.$refs[formName].resetFields();
                this.fileList = []
                this.transaction.merchant = ''
                this.transaction.date = ''
            }
            
            this.fullscreenLoading = false
        },
        handlePreview(file) {
            this.dialogVisible = true
            this.receiptPreview = file.url
        },
        handleRemove(file, fileList) {
            this.transaction.receipts = fileList
            this.fileList = fileList
            // todo: delete the file from firestore
        },
        handleChange(file, fileList) {
            this.fileList = fileList

        },
        uploadImageToFirestorage(formName) {
            var storageRef          = firebase.app.storage().ref()
            var uploadTaskRemaining = this.fileList.length
            var self                = this
            
            this.fileList.forEach(function (file) {
                if (file.hasOwnProperty( 'status' ) && file.status === 'success') {
                    uploadTaskRemaining--
                    if (uploadTaskRemaining === 0) {
                        self.fullscreenLoading = false
                        self.handleSave(formName)
                    }
                    return
                }

                var fileRef    = 'receipts/'+ generatePushID() + '.' + file.name.split('.').pop()
                var imagesRef  = storageRef.child(fileRef);
                var uploadTask = imagesRef.put(file.raw, {
                    name: file.name
                })

                file.ref = fileRef

                uploadTask.on('state_changed', function(snapshot) {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                   
                },
                function(error) {
                    // Handle unsuccessful uploads
                    console.log('error uploading file:', error);
                },
                function() {
                    console.log('upload success');
                    self.transaction.receipts.push({
                        url: file.ref,
                        name: file.name 
                    })

                    uploadTaskRemaining--

                    if (uploadTaskRemaining === 0) {
                        self.fullscreenLoading = false
                        self.handleSave(formName)
                    }

                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    // uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
                        
                    //     self.transaction.receipts.push({
                    //         url: downloadURL,
                    //         name: file.name 
                    //     })

                    //     uploadTaskRemaining--

                    //     if (uploadTaskRemaining === 0) {
                    //         self.fullscreenLoading = false
                    //         self.handleSave(formName)
                    //     }

                    // });
                });
            })
        }
    },
    created() {
        const id = this.$route.params.id
        if (!id) {
            return
        }

        var storageRef = firebase.app.storage().ref()

        this.isEdit = true

        var self = this
        firebase.transactions.doc(id).get().then(function(querySnapshot) {
            var data = querySnapshot.data()
            
            if (data.date && data.date.toDate) {
                data.date = data.date.toDate().toLocaleString()
            }
            
            data.id = id
            if (!data.hasOwnProperty( 'receipts' )) {
                data.receipts = []
            }
            
            self.transaction = data
            
            data.receipts.forEach(function(receipt, index) {
                storageRef.child(receipt.url).getDownloadURL().then((url) => {
                    self.fileList.push({
                        url: url,
                        name: receipt.name
                    })
                });

            })

        }).catch(function(error) {
            console.log('Firebase error: ', error);
            
        });
        // this.transaction = revenue
        // console.log(revenue);
    }
}
</script>


<style lang="scss">
.revenue-add {
    padding: 1.5rem;
    background-color: #FFF;
    margin: 1.5rem 2rem;
    box-shadow: 0 0 12px #EFEFEF;
    border-radius: 7px;
}

.field {
    padding:1rem;
    display: flex;
    flex-direction: column;
    width:400px;
    
    .label {
        margin-bottom:1rem;
        font-weight: bold;
    }
}

.el-input.el-input--suffix {
    width:300px;
}

.el-date-editor.el-input {
    width:300px !important;
}

.receipt__preview {
    // display: flex;
    justify-content: center;

    img {
        max-height:60vh;
        max-width: 100%;
    }
}
</style>

