<template>
    <el-row class="d-aside" id="leftSideBar">
        <el-col :span="24">
            <el-menu
                class="el-menu-vertical-aside"
                @open="handleOpen"
                @close="handleClose"
                :collapse="isCollapse"
                background-color="#1e2835"
                text-color="#53a6fa"
                active-text-color="#ffd04b">
                <!-- <div class="collapse-btn" v-on:click="isCollapse = !isCollapse">
                    <i class="el-icon-setting"></i>
                </div> -->
                <!-- <el-menu-item index="2">
                    <router-link to="/">
                        <i class="el-icon-menu"></i>
                        <span>Dashboard</span>
                    </router-link>
                </el-menu-item> -->
                <el-menu-item index="0" class="d-aside__label">TRACK</el-menu-item>
                <el-menu-item index="1">
                    <router-link to="/maps">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                            <g>
                                <g>
                                    <path d="M256,0C156.748,0,76,80.748,76,180c0,33.534,9.289,66.26,26.869,94.652l142.885,230.257
                                        c2.737,4.411,7.559,7.091,12.745,7.091c0.04,0,0.079,0,0.119,0c5.231-0.041,10.063-2.804,12.75-7.292L410.611,272.22
                                        C427.221,244.428,436,212.539,436,180C436,80.748,355.252,0,256,0z M384.866,256.818L258.272,468.186l-129.905-209.34
                                        C113.734,235.214,105.8,207.95,105.8,180c0-82.71,67.49-150.2,150.2-150.2S406.1,97.29,406.1,180
                                        C406.1,207.121,398.689,233.688,384.866,256.818z"/>
                                </g>
                            </g>
                            <g>
                                <g>
                                    <path d="M256,90c-49.626,0-90,40.374-90,90c0,49.309,39.717,90,90,90c50.903,0,90-41.233,90-90C346,130.374,305.626,90,256,90z
                                        M256,240.2c-33.257,0-60.2-27.033-60.2-60.2c0-33.084,27.116-60.2,60.2-60.2s60.1,27.116,60.1,60.2
                                        C316.1,212.683,289.784,240.2,256,240.2z"/>
                                </g>
                            </g>
                        </svg>

                        <span>Maps</span>
                    </router-link>
                </el-menu-item>

                <el-menu-item index="2" >
                    <router-link to="/cstatus">
                        <i class="el-icon-document"></i>
                        <span>Current Status</span>
                    </router-link>
                </el-menu-item>

                <el-menu-item index="3" class="d-aside__label">MANAGE</el-menu-item>

                <el-menu-item index="4" >
                    <router-link to="/vehicles">
                        
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 491.1 491.1" style="enable-background:new 0 0 491.1 491.1;" xml:space="preserve">
                        <path transform="translate(0 -540.36)" d="M401.5,863.31c-12,0-23.4,4.7-32,13.2c-8.6,8.6-13.4,19.8-13.4,31.8s4.7,23.2,13.4,31.8
                            c8.7,8.5,20,13.2,32,13.2c24.6,0,44.6-20.2,44.6-45S426.1,863.31,401.5,863.31z M401.5,933.31c-13.8,0-25.4-11.4-25.4-25
                            s11.6-25,25.4-25c13.6,0,24.6,11.2,24.6,25S415.1,933.31,401.5,933.31z M413.1,713.41c-1.8-1.7-4.2-2.6-6.7-2.6h-51.3
                            c-5.5,0-10,4.5-10,10v82c0,5.5,4.5,10,10,10h81.4c5.5,0,10-4.5,10-10v-54.9c0-2.8-1.2-5.5-3.3-7.4L413.1,713.41z M426.5,792.81
                            h-61.4v-62.1h37.4l24,21.6V792.81z M157.3,863.31c-12,0-23.4,4.7-32,13.2c-8.6,8.6-13.4,19.8-13.4,31.8s4.7,23.2,13.4,31.8
                            c8.7,8.5,20,13.2,32,13.2c24.6,0,44.6-20.2,44.6-45S181.9,863.31,157.3,863.31z M157.3,933.31c-13.8,0-25.4-11.4-25.4-25
                            s11.6-25,25.4-25c13.6,0,24.6,11.2,24.6,25S170.9,933.31,157.3,933.31z M90.6,875.61H70.5v-26.6c0-5.5-4.5-10-10-10s-10,4.5-10,10
                            v36.6c0,5.5,4.5,10,10,10h30.1c5.5,0,10-4.5,10-10S96.1,875.61,90.6,875.61z M141.3,821.11c0-5.5-4.5-10-10-10H10
                            c-5.5,0-10,4.5-10,10s4.5,10,10,10h121.3C136.8,831.11,141.3,826.71,141.3,821.11z M30.3,785.01l121.3,0.7c5.5,0,10-4.4,10.1-9.9
                            c0.1-5.6-4.4-10.1-9.9-10.1l-121.3-0.7c-0.1,0-0.1,0-0.1,0c-5.5,0-10,4.4-10,9.9C20.3,780.51,24.8,785.01,30.3,785.01z M50.7,739.61
                            H172c5.5,0,10-4.5,10-10s-4.5-10-10-10H50.7c-5.5,0-10,4.5-10,10S45.2,739.61,50.7,739.61z M487.4,726.11L487.4,726.11l-71.6-59.3
                            c-1.8-1.5-4-2.3-6.4-2.3h-84.2v-36c0-5.5-4.5-10-10-10H60.5c-5.5,0-10,4.5-10,10v73.2c0,5.5,4.5,10,10,10s10-4.5,10-10v-63.2h234.8
                            v237.1h-82c-5.5,0-10,4.5-10,10s4.5,10,10,10h122.1c5.5,0,10-4.5,10-10s-4.5-10-10-10h-20.1v-191.1h80.6l65.2,54l-0.7,136.9H460
                            c-5.5,0-10,4.5-10,10s4.5,10,10,10h20.3c5.5,0,10-4.4,10-9.9l0.8-151.6C491,730.91,489.7,728.01,487.4,726.11z"/>
                            <g>
                            </g>
                        </svg>


                        <span>Vehicles</span>
                    </router-link>
                </el-menu-item>

                <el-menu-item index="5">
                    <router-link to="/revenue">
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 212.755 212.755" xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 212.755 212.755">
                            <g>
                                <path d="M106.377,0C47.721,0,0,47.721,0,106.377s47.721,106.377,106.377,106.377s106.377-47.721,106.377-106.377   S165.034,0,106.377,0z M106.377,198.755C55.44,198.755,14,157.314,14,106.377S55.44,14,106.377,14s92.377,41.44,92.377,92.377   S157.314,198.755,106.377,198.755z"/>
                                <path d="m113.377,100.096v-39.744c3.961,1.471 7.417,4.17 9.82,7.82 2.127,3.229 6.468,4.123 9.696,1.997 3.229-2.126 4.123-6.467 1.996-9.696-5.029-7.636-12.778-12.82-21.512-14.647v-11.12c0-3.866-3.134-7-7-7s-7,3.134-7,7v11.099c-15.493,3.23-27.168,16.989-27.168,33.426 0,16.437 11.676,30.198 27.168,33.428v39.744c-3.961-1.471-7.417-4.17-9.82-7.82-2.127-3.229-6.468-4.124-9.696-1.997-3.229,2.126-4.123,6.467-1.996,9.696 5.029,7.636 12.778,12.82 21.512,14.647v11.119c0,3.866 3.134,7 7,7s7-3.134 7-7v-11.098c15.493-3.23 27.168-16.989 27.168-33.426-2.84217e-14-16.437-11.675-30.198-27.168-33.428zm-27.168-20.865c0-8.653 5.494-16.027 13.168-18.874v37.748c-7.674-2.847-13.168-10.221-13.168-18.874zm27.168,73.166v-37.748c7.674,2.847 13.168,10.221 13.168,18.874s-5.493,16.027-13.168,18.874z"/>
                            </g>
                        </svg>



                        <span>Revenues</span>
                    </router-link>
                </el-menu-item>
                <el-menu-item index="6" class="d-aside__label">ANALYZE</el-menu-item>
                <el-menu-item index="7" class="has-action-btn">
                    <router-link to="/geofences">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
                        <g>
                            <g>
                                <g>
                                    <path d="M460.896,241.842c0-25.724-19.396-46.99-44.329-49.977V62.755c0-7.905-5.128-14.599-12.761-16.656
                                        c-7.631-2.055-15.428,1.153-19.4,7.986c-27.273,46.922-70.229,78.994-127.675,95.327c-43.439,12.35-79.285,10.56-79.631,10.539
                                        c-0.526-0.03-1.049-0.005-1.567,0.047H83.682C37.54,159.998,0,197.538,0,243.68c0,43.461,33.307,79.277,75.734,83.294
                                        l27.704,113.922c3.668,15.084,17.072,25.619,32.596,25.619h19.228c10.359,0,19.972-4.671,26.374-12.816s8.669-18.59,6.222-28.656
                                        l-23.755-97.682h11.413c0.52,0.053,1.045,0.078,1.575,0.048c0.356-0.02,36.201-1.812,79.64,10.539
                                        c57.445,16.333,100.401,48.405,127.675,95.326c3.171,5.457,8.78,8.602,14.796,8.602c1.52,0,3.065-0.201,4.604-0.615
                                        c7.633-2.057,12.761-8.75,12.761-16.655V295.494c24.933-2.986,44.329-24.252,44.329-49.976V241.842z M165.264,416.777
                                        l3.159,12.992c0.989,4.065,0.074,8.283-2.512,11.572c-2.584,3.289-6.466,5.175-10.649,5.175h-19.228
                                        c-6.269,0-11.681-4.254-13.162-10.345L96.411,327.362h47.109l7.063,29.045h-4.78c-5.523,0-10,4.477-10,10s4.477,10,10,10h9.643
                                        l4.954,20.37h-3.134c-5.523,0-10,4.477-10,10s4.477,10,10,10H165.264z M166.533,264.926h-62.146c-5.523,0-10,4.477-10,10
                                        s4.477,10,10,10h62.146v22.436h-14.609c-0.183-0.01-0.365-0.023-0.551-0.023H83.682c-0.095,0-0.189,0.014-0.284,0.016
                                        C48.415,307.2,20,278.699,20,243.68c0-35.114,28.568-63.682,63.682-63.682h82.851V264.926z M396.565,201.493v84.374v128.969
                                        c-29.968-46.606-76.453-79.813-135.04-96.316c-32.439-9.138-60.458-10.963-74.992-11.2v-32.394V180.04
                                        c14.536-0.237,42.556-2.063,74.992-11.2c58.586-16.504,105.072-49.71,135.04-96.316V201.493z M440.894,245.518
                                        c0,14.674-10.467,26.949-24.329,29.749v-63.174c13.861,2.8,24.329,15.075,24.329,29.749V245.518z"/>
                                    <path d="M463.889,185.337c2.403,0,4.814-0.861,6.73-2.606l28.843-26.268c4.083-3.719,4.379-10.043,0.66-14.127
                                        c-3.719-4.083-10.043-4.378-14.127-0.66l-28.842,26.267c-4.083,3.719-4.379,10.043-0.66,14.127
                                        C458.467,184.237,461.173,185.337,463.889,185.337z"/>
                                    <path d="M470.62,304.63c-4.084-3.718-10.408-3.423-14.127,0.66c-3.719,4.083-3.423,10.408,0.66,14.127l28.842,26.267
                                        c1.918,1.746,4.328,2.607,6.731,2.607c2.716,0,5.423-1.1,7.396-3.267c3.719-4.083,3.423-10.408-0.66-14.127L470.62,304.63z"/>
                                    <path d="M502,233.68h-22.147c-5.523,0-10,4.477-10,10s4.477,10,10,10H502c5.523,0,10-4.477,10-10S507.523,233.68,502,233.68z"/>
                                    <path d="M72.42,271.104c-0.25-0.61-0.56-1.19-0.92-1.73c-0.37-0.55-0.78-1.06-1.24-1.52c-0.47-0.46-0.98-0.88-1.53-1.24
                                        c-0.54-0.36-1.12-0.67-1.72-0.92c-0.61-0.25-1.24-0.44-1.87-0.57c-1.29-0.26-2.62-0.26-3.91,0c-0.64,0.13-1.27,0.32-1.87,0.57
                                        c-0.6,0.25-1.19,0.56-1.73,0.92c-0.55,0.36-1.06,0.78-1.52,1.24c-0.46,0.46-0.88,0.97-1.24,1.52c-0.36,0.54-0.67,1.12-0.92,1.73
                                        c-0.25,0.6-0.44,1.23-0.57,1.87s-0.2,1.3-0.2,1.95s0.07,1.31,0.2,1.96c0.13,0.63,0.32,1.26,0.57,1.87
                                        c0.25,0.6,0.56,1.18,0.92,1.72c0.36,0.55,0.78,1.06,1.24,1.52c0.46,0.46,0.97,0.88,1.52,1.25c0.54,0.36,1.13,0.67,1.73,0.92
                                        c0.6,0.25,1.23,0.44,1.87,0.57c0.65,0.13,1.3,0.19,1.95,0.19c0.66,0,1.31-0.06,1.96-0.19c0.64-0.13,1.26-0.32,1.87-0.57
                                        c0.6-0.25,1.18-0.56,1.72-0.92c0.55-0.37,1.06-0.79,1.53-1.25c0.46-0.46,0.87-0.97,1.24-1.52c0.36-0.54,0.67-1.12,0.92-1.72
                                        c0.25-0.61,0.44-1.24,0.57-1.87c0.13-0.65,0.19-1.31,0.19-1.96c0-0.65-0.06-1.31-0.19-1.95S72.67,271.704,72.42,271.104z"/>
                                </g>
                            </g>
                        </g>
                        </svg>


                        <span>Region Alerts</span>
                    </router-link>
                    <!-- <div>
                        <el-button class="aside-action__btn" >Add</el-button>
                    </div> -->
                </el-menu-item>

                <el-menu-item index="8" class="has-action-btn">
                    <router-link to="/reports">
                        <i class="el-icon-document"></i>
                        <span>Reports</span>
                    </router-link>
                    <!-- <div>
                        <el-button class="aside-action__btn" >Add</el-button>
                    </div> -->
                </el-menu-item>

                

                
                <!-- <el-menu-item index="7" class="has-action-btn">
                    <router-link to="/media">
                        <i class="el-icon-picture"></i>
                        <span>Media</span>
                    </router-link>
                    <div>
                        <el-button class="aside-action__btn" >Add</el-button>
                    </div>
                </el-menu-item> -->
            </el-menu>
        </el-col>
    </el-row>
</template>

<style lang="scss" scoped>
    .el-menu-vertical-aside:not(.el-menu--collapse) {
        height: calc(100vh - 52px);
    }

    .el-menu {
        border-right:0;

        a {
            color:#FFF;
            display: block;
            font-size: 1rem;
            i {
              position:relative;
              top:-2px;
            }
        }
    }

    .el-menu-item {
        padding:0;
        span {
            margin-left:10px;
        }
    }

    .has-action-btn {
        display: flex;
        flex-direction: row;
        a {
            width:70%;
        }
    }

    .aside-action__btn {
        padding:5px 12px;
        font-size:12px;
        font-weight: bold;
    }

    .d-aside {
        svg {
            margin-right:5px;
            margin-left:5px;
            fill:#999;
            width: 18px;
            height:18px;
        }

        i:before {
            color:#999;
        }
    }

    .collapse-btn {
        position:absolute;
        right:-32px;
        background-color:#DDD;
        z-index:1000;
        cursor:pointer;
        padding:.5rem;


        i:before {
            width:44px;
            height:44px;
            
        }
    }

    .el-menu-vertical-aside.el-menu--collapse.el-menu {
        height:calc(100vh - 60px);
    }

    .el-menu-item {
        span {
            font-weight: 500;
        }
    }
    .el-menu-item.is-active {
        .router-link-active {
            color:#333;
            svg {
                fill:#53a6fa !important;
            }

            span {
                color:#53a6fa;
            }

            i:before {
                color:#53a6fa;
            }
        }
    }

    .d-aside__label {
        color:#F80 !important;
        font-weight: bold;
        margin-top:2rem;

        &:hover {
            background-color:#1e2835 !important;
            cursor: default;
        }
    }

    @media only screen and (max-width: 600px) {
        .el-menu-vertical-aside:not(.el-menu--collapse) {
            height: unset;
        }
    }
</style>

<script>
    export default {
        name: 'AsideBar',
        data() {
            return {
                isCollapse: false
            };
        },
        methods: {
            handleOpen(key, keyPath) {
                console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                console.log(key, keyPath);
            }
        }
    }
</script>