<template>
    <div class="userinfo" :class="{ 'is-open': isOpen }">
        <a class="userinfo-header" href="#" aria-expanded="true" v-on:click="isOpen = !isOpen">
            <figure class="profile-picture">
                <!-- <img :src="user_image" alt="Joseph Doe" class="rounded-circle"> -->
                <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                    viewBox="0 0 53 53" style="enable-background:new 0 0 53 53;" xml:space="preserve">
                    <path style="fill:#E7ECED;" d="M18.613,41.552l-7.907,4.313c-0.464,0.253-0.881,0.564-1.269,0.903C14.047,50.655,19.998,53,26.5,53
                        c6.454,0,12.367-2.31,16.964-6.144c-0.424-0.358-0.884-0.68-1.394-0.934l-8.467-4.233c-1.094-0.547-1.785-1.665-1.785-2.888v-3.322
                        c0.238-0.271,0.51-0.619,0.801-1.03c1.154-1.63,2.027-3.423,2.632-5.304c1.086-0.335,1.886-1.338,1.886-2.53v-3.546
                        c0-0.78-0.347-1.477-0.886-1.965v-5.126c0,0,1.053-7.977-9.75-7.977s-9.75,7.977-9.75,7.977v5.126
                        c-0.54,0.488-0.886,1.185-0.886,1.965v3.546c0,0.934,0.491,1.756,1.226,2.231c0.886,3.857,3.206,6.633,3.206,6.633v3.24
                        C20.296,39.899,19.65,40.986,18.613,41.552z"/>
                    <g>
                        <path style="fill:#556080;" d="M26.953,0.004C12.32-0.246,0.254,11.414,0.004,26.047C-0.138,34.344,3.56,41.801,9.448,46.76
                            c0.385-0.336,0.798-0.644,1.257-0.894l7.907-4.313c1.037-0.566,1.683-1.653,1.683-2.835v-3.24c0,0-2.321-2.776-3.206-6.633
                            c-0.734-0.475-1.226-1.296-1.226-2.231v-3.546c0-0.78,0.347-1.477,0.886-1.965v-5.126c0,0-1.053-7.977,9.75-7.977
                            s9.75,7.977,9.75,7.977v5.126c0.54,0.488,0.886,1.185,0.886,1.965v3.546c0,1.192-0.8,2.195-1.886,2.53
                            c-0.605,1.881-1.478,3.674-2.632,5.304c-0.291,0.411-0.563,0.759-0.801,1.03V38.8c0,1.223,0.691,2.342,1.785,2.888l8.467,4.233
                            c0.508,0.254,0.967,0.575,1.39,0.932c5.71-4.762,9.399-11.882,9.536-19.9C53.246,12.32,41.587,0.254,26.953,0.004z"/>
                    </g>
                </svg>
            </figure>
            <div class="profile-info" >
                <span class="name">{{ name }}</span>
                <span class="role">{{ category }}</span>
            </div>
            <div class="profile-icon" >
                <i class="el-icon-arrow-down"></i>
                <i class="el-icon-arrow-up"></i>
            </div>
        </a>

        <div class="dropdown-menu"  v-on:mouseleave="isOpen = false">
            <el-menu class="el-menu-vertical-demo" theme="dark" :router="true">
                <el-menu-item index="11" :route="{name:'profile'}"><i class="el-icon-time"></i>Profile</el-menu-item>
                <el-menu-item index="22" :route="{name:'logout'}"><i class="el-icon-view"></i>Logout</el-menu-item>
            </el-menu> 
        </div>
    </div>
</template>


<script>
import consts from '@/configs/const.js'

export default {
    name: 'UserInfo',
    data() {
        return {
            user_image: '/images/logged-user.jpg',
            isOpen    : false,
            name      : '',
            category  : '',
            address   : '',
            email     : ''
        }
    },
    methods: {
        getData() {
            // duplicate code... need to remove this later
            var self = this
            this.$http.get('/customer/handshake')
                .then(function (response) {
                    
                    var info = response.data.info

                    self.name     = info.name
                    self.email    = info.email
                    self.address  = info.address
                    self.category = {
                        1: 'standard',
                        2: 'business',
                        3: 'premium'
                    }[info.category]
                    
                    consts.uid = info._id

                })
                .catch(function(err) {
                    console.log(err);
                })
        }
    },
    created() {
        this.getData()
        
    },
    mounted() {
        
    }
}
</script>


<style lang="scss">

.userinfo {

    &.is-open {
        .dropdown-menu {
            display: block;
        }

        .el-icon-arrow-down {
            display: none;
        }

        .el-icon-arrow-up {
            display: inline-block;
        }
    }

    .dropdown-menu {
        position:absolute;
        background-color: #FFF;
        z-index:100;
        box-shadow:0 10px 12px rgba(170, 170, 170, 0.49);
        display: none;
        width: 100%;
        ul {
            margin-left: 0;
            padding:0;
        }

        a {
            display: block;
            line-height: 30px;
            font-size:.7rem;
            padding:.5rem;

            &:hover {
                background-color: #FAFAFA;
                i:before {
                    color:#16A085;
                }
            }

            
        }

    
    }

    .el-icon-arrow-down {
        display: inline-block;
    }

    .el-icon-arrow-up {
        display: none;
    }
}

.userinfo-header {
    display: flex;
    flex-direction: row;
}

.profile-info {
    display: flex;
    flex-direction: column;
    margin:10px;

    .name {
        color:#000;
        font-weight: bold;
    }

    .role {
        color:#777;
    }

    span {
        line-height: 20px;
        font-size:12px;
    }
}

.profile-picture {
    margin:0;
    height:50px;
    width:50px;
    img {
        height:100%;
        width:100%;
    }
}


</style>
