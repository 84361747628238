import api from '../_api'

const addDocument = (context, doc) => {
    return api.addDocument(doc)
            .then((response) => {
                context.commit('DOCUMENT_ADDED', response)

            })
            .catch((error) => {
                console.error(error);
            });
    
}

export default {
    addDocument
}