import firebase from '@/firebaseConfig.js'
import vars from '@/configs/const.js'

const fetchRevenues = function() {
    return new Promise((resolve) => {
        firebase.transactions.where('orgid', '==', vars.uid).get().then((querySnapshot) => {
            let transactions = []

            querySnapshot.forEach((doc) => {
                let transaction = doc.data()
                transaction.id  = doc.id
                if (transaction.date && transaction.date.toDate) {
                    transaction.date = transaction.date.toDate().toLocaleString()
                }
                
                transactions.push( transaction )
            })
            console.log(transactions);
            resolve(transactions)
        })
    });

}

// const fetchRevenueById = function(revenueId) {

//     return new Promise((resolve, reject) => {
//         firebase.transactions.doc(revenueId).get().then(function(querySnapshot) {
//             console.log(querySnapshot);
//             resolve( revenueId )
//         }).catch(function(error) {
//             console.error("Error removing document: ", error);
//             reject(error)
//         });
//     })
// }

/**
 * 
 * https://firebase.google.com/docs/firestore/manage-data/add-data
 */
const addRevenue = function(newRevenue) {
    
    return new Promise((resolve, reject) => {
        newRevenue.orgid = vars.uid
        console.log('newRevenue:: ', newRevenue);
        firebase.transactions.add(newRevenue)
            .then(function( docRef ) {
                newRevenue.id = docRef.id

                if (newRevenue.date && newRevenue.date.toDate) {
                    newRevenue.date = newRevenue.date.toDate().toLocaleString()
                }

                resolve(newRevenue)

            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
                reject(error)
            });
        })
    }

const deleteRevenue = function(revenueId) {

    return new Promise((resolve, reject) => {
        firebase.transactions.doc(revenueId).delete().then(function() {
            console.log("Document successfully deleted!");
            resolve( revenueId )
        }).catch(function(error) {
            console.error("Error removing document: ", error);
            reject(error)
        });
    })
}

// var washingtonRef = db.collection("cities").doc("DC");

// // Set the "capital" field of the city 'DC'
// return washingtonRef.update({
//     capital: true
// })
// .then(function() {
//     console.log("Document successfully updated!");
// })
// .catch(function(error) {
//     // The document probably doesn't exist.
//     console.error("Error updating document: ", error);
// });

const updateRevenue = function(transaction) {
    return new Promise((resolve, reject) => {
        
        firebase.transactions.doc(transaction.id).update( transaction ).then(function() {
            console.log("Document successfully updated!");
            resolve(transaction)

        }).catch(function(error) {
            console.error("Error updating document: ", error);
            reject(error)
        });
    })
}

export default {
    fetchRevenues,
    // fetchRevenueById,
    deleteRevenue,
    updateRevenue,
    addRevenue
};