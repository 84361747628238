<template>
    <div class="container">
        <div class="vehicle__head">
            <h2>VEHICLE</h2>
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/vehicles' }">Vehicles</el-breadcrumb-item>
                <el-breadcrumb-item>{{ vehicle._id }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <el-row :span="24">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                <div class="vehicle-edit">

                    <div class="vehicle__type-icon">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                            viewBox="0 0 496.474 496.474" style="enable-background:new 0 0 496.474 496.474;" xml:space="preserve">
                            <path style="fill:#E95353;" d="M489.544,269.628c-0.729-14.739-6.206-28.858-15.205-40.572
                                c-20.294-26.422-24.25-47.399-24.25-47.399c-15.934-41.751-30.099-69.57-40.51-87.226c-10.954-18.541-29.898-31.03-51.262-33.776
                                c-42.961-5.554-177.23-5.554-220.191,0c-21.349,2.762-40.293,15.251-51.247,33.776c-10.411,17.656-24.56,45.475-40.51,87.226
                                c0,0-3.956,20.977-24.25,47.399c-8.983,11.714-14.476,25.833-15.189,40.572c-1.536,31.977,7.727,39.005,16.012,111.368
                                c0.652,5.694,5.461,9.976,11.202,9.976h428.203c5.741,0,10.55-4.298,11.202-9.976c2.327-20.294,9.232-61.456,9.232-61.456
                                C488.83,299.076,490.149,282.397,489.544,269.628z"/>
                            <path style="fill:#168DE2;" d="M441.074,179.982c-12.567-31.449-25.383-58.911-38.136-80.803
                                c-9.666-16.555-26.717-28.113-45.583-30.642c-43.055-5.71-175.476-5.663-218.221,0c-18.866,2.529-35.918,14.088-45.584,30.642
                                c-12.924,22.171-25.91,50.005-38.291,80.803C183.662,190.082,312.655,190.082,441.074,179.982z"/>
                            <path style="fill:#FFFFFF;" d="M422.844,216.349c-4.903,1.536-9.464,3.693-13.281,5.834c-6.951,3.879-14.088,7.494-21.535,10.318
                                c-12.35,4.686-14.445,11.683-14.445,11.683c-0.372,0.481-0.729,0.962-1.071,1.458c-9.371,13.917,2.932,32.442,19.549,30.084
                                c24.033-3.398,43.83-10.364,53.884-14.398c5.756-2.312,10.255-7.121,11.729-13.157c0.14-0.574,0.264-1.148,0.372-1.707
                                C461.74,226.543,442.191,210.314,422.844,216.349z"/>
                            <path style="fill:#FFE21F;" d="M448.522,327.857h-26.733c-4.018,0-6.035,4.856-3.196,7.711l26.733,26.733
                                c2.839,2.839,7.711,0.822,7.711-3.196v-26.733C453.036,329.874,451.019,327.857,448.522,327.857z"/>
                            <path style="fill:#454545;" d="M473.547,380.965c-0.652,5.71-5.477,10.007-11.217,10.007h-51.433v29.851
                                c0,10.566,8.564,19.146,19.146,19.146h32.303c10.566,0,19.146-8.564,19.146-19.146v-93.448
                                C479.366,340.315,475.239,366.179,473.547,380.965z"/>
                            <path style="fill:#ED6262;" d="M496.107,159.083c-1.552-6.951-8.083-11.667-15.205-11.667h-12.909
                                c-8.27,0-14.972,6.703-14.972,14.972v9.2h-6.842l3.925,9.325h15.05c7.789,0,15.5-1.676,22.575-4.918
                                C494.105,173.078,497.674,166.096,496.107,159.083z"/>
                            <circle style="fill:#FFBD49;" cx="431.315" cy="240.46" r="18.82"/>
                            <circle style="fill:#FFDB6F;" cx="393.458" cy="255.308" r="13.824"/>
                            <polygon style="fill:#6F6F6F;" points="357.634,255.324 336.021,292.963 160.452,292.963 138.839,255.324 "/>
                            <path style="fill:#FFFFFF;" d="M123.945,245.642c-0.326-0.496-0.683-0.977-1.071-1.458c0,0-2.095-6.997-14.445-11.683
                                c-7.447-2.824-14.569-6.439-21.535-10.318c-3.832-2.141-8.378-4.298-13.281-5.834c-19.332-6.051-38.896,10.193-35.219,30.115
                                c0.109,0.574,0.233,1.133,0.357,1.707c1.474,6.035,5.973,10.845,11.729,13.157c10.054,4.034,29.851,11,53.9,14.398
                                C121.012,278.084,133.316,259.559,123.945,245.642z"/>
                            <path style="fill:#FFE21F;" d="M74.684,327.857H47.951c-2.498,0-4.515,2.017-4.515,4.515v26.733c0,4.018,4.872,6.035,7.711,3.196
                                l26.733-26.733C80.719,332.729,78.702,327.857,74.684,327.857z"/>
                            <path style="fill:#777777;" d="M426.226,374.511c-27.834-48.159-59.842-46.654-59.842-46.654H130.073
                                c0,0-31.992-1.505-59.842,46.654H22.119l0.59,6.206c0.543,5.834,5.415,10.256,11.248,10.256h428.56
                                c5.834,0,10.705-4.422,11.249-10.255l0.59-6.206L426.226,374.511L426.226,374.511z"/>
                            <path style="fill:#454545;" d="M34.143,390.988c-5.741,0-10.566-4.313-11.217-10.007c-1.691-14.786-5.834-40.665-7.944-53.589
                                v93.448c0,10.566,8.564,19.146,19.146,19.146H66.43c10.566,0,19.146-8.564,19.146-19.146v-29.851H34.143z"/>
                            <path style="fill:#ED6262;" d="M43.436,171.588v-9.2c0-8.27-6.703-14.972-14.957-14.972H15.571c-7.121,0-13.653,4.717-15.205,11.667
                                c-1.567,6.997,2.017,13.995,8.378,16.896c7.09,3.243,14.786,4.918,22.575,4.918h15.05l3.925-9.325h-6.858V171.588z"/>
                            <circle style="fill:#FFBD49;" cx="65.142" cy="240.46" r="18.82"/>
                            <circle style="fill:#FFDB6F;" cx="103.03" cy="255.308" r="13.824"/>
                            <g>
                                <path style="fill:#6F6F6F;" d="M348.294,341.029H148.179c-3.134,0-5.663,2.544-5.663,5.663c0,3.134,2.529,5.663,5.663,5.663
                                    h200.099c3.134,0,5.663-2.544,5.663-5.663C353.957,343.558,351.412,341.029,348.294,341.029z"/>
                                <path style="fill:#6F6F6F;" d="M348.294,363.635H148.179c-3.134,0-5.663,2.544-5.663,5.663c0,3.134,2.529,5.663,5.663,5.663
                                    h200.099c3.134,0,5.663-2.544,5.663-5.663C353.957,366.164,351.412,363.635,348.294,363.635z"/>
                            </g>
                            <path style="fill:#3AA2EB;" d="M420.098,132.009v1.939c0,7.804-6.408,14.057-14.367,13.731c-7.339-0.465-12.955-7.028-12.955-14.522
                                v-27.834c0-6.563-5.461-11.559-12.024-11.388c-0.155,0-0.155,0-0.31,0c0,0,0,0-0.155,0c-6.563-0.155-12.024,4.841-12.024,11.388
                                v25.181c0,7.804-6.718,14.057-14.522,13.576c-7.339-0.31-12.955-7.028-12.955-14.367v-21.892c0-6.563-5.461-12.024-12.179-12.024
                                h-0.931c-6.253,0-11.404,5.151-11.404,11.404v28.47c0,7.804-6.563,14.041-14.522,13.731c-7.339-0.465-12.8-7.028-12.8-14.522V96.712
                                c0-6.082-5.306-11.233-11.559-10.768h-0.931c-6.408,0-11.543,5.151-11.543,11.404v39.021c0,7.494-6.082,13.731-13.731,13.731
                                c-7.339,0-13.421-5.927-13.576-13.265v-32.752c0-7.494-5.616-14.041-12.955-14.522c-7.959-0.31-14.367,5.927-14.367,13.731
                                l-0.155,30.255c0,7.804-6.392,14.041-14.367,13.731c-7.199-0.45-12.66-6.78-12.878-13.948V97.829c0-7.37-5.539-13.964-12.909-14.398
                                c-7.897-0.341-14.414,5.88-14.414,13.7v37.19c-0.217,7.618-6.609,13.684-14.445,13.374c-7.339-0.465-12.8-7.028-12.8-14.522v-27.85
                                c0-6.563-5.616-11.559-12.179-11.388c-0.155,0-0.155,0-0.155,0c-0.155,0-0.155,0-0.155,0c-6.718-0.155-12.179,4.841-12.179,11.388
                                v25.181c0,7.804-6.563,14.057-14.367,13.576c-6.796-0.264-11.854-5.942-12.722-12.521c-7.215,14.941-14.367,31.123-21.318,48.407
                                c128.403,10.116,257.412,10.116,385.815,0C434.139,162.636,427.126,146.655,420.098,132.009z"/>
                        </svg>

                    </div>
                    <div class="detail__field detail__status">
                        
                            <span v-if="vehicle.status === 1">Vehicle moving</span>
                            <span v-else>
                                <span v-if="vehicle.status === 0" class="text-bold">Engine off</span> 
                                <span v-if="vehicle.status === 2" class="text-bold">Not responding</span> 
                                <span v-if="vehicle.status === 3" class="text-bold">Idle</span> 

                                <span v-if="vehicle.hasOwnProperty('lkt')">        
                                    since <span style="font-weight:bold">{{ new Date(vehicle.lkt).toDateString() }}</span></span>
                                </span>
                    </div>


                    <el-tabs type="card">
                        <el-tab-pane>
                            <span slot="label"><i class="el-icon-date"></i> Details</span>

                                    <el-form ref="form" :model="vehicle" label-width="200px">
                                        <el-row :gutter="5">
                                            <el-col :span="12">

                                                <div class="form-field__title">VEHICLE INFORMATION</div>
                                                
                                                <el-form-item label="IMEI Number">{{ vehicle._id }}</el-form-item>

                                                <el-form-item label="Sim Number">{{ vehicle.sim_number }}</el-form-item>

                                                <el-form-item label="Device Type">{{ vehicle.device_type }}</el-form-item>

                                                <el-form-item label="Vehicle Type">
                                                    <el-select v-model="vehicle.v_type" clearable placeholder="Select">
                                                        <el-option label="CAR" value="CAR">CAR</el-option>
                                                        <el-option label="BIKE" value="BIKE">BIKE</el-option>
                                                        <el-option label="BUS" value="BUS">BUS</el-option>
                                                        <el-option label="TRUCK" value="TRUCK">TRUCK</el-option>
                                                    </el-select>
                                                </el-form-item>

                                                <!-- <el-form-item label="Vehicle Number">
                                                    <el-input class="vehicle-input w300" v-model="vehicle.bus_number"></el-input>
                                                </el-form-item> -->

                                                <el-form-item label="Registration Number">
                                                    <el-input class="vehicle-input w300" v-model="vehicle.reg_no"></el-input>
                                                </el-form-item>

                                                <!-- <el-form-item label="Driver Name">
                                                    <el-input v-model="vehicle.d_name"></el-input>
                                                </el-form-item> -->

                                                <!-- <el-form-item label="Driver Number">
                                                    <el-input v-model="vehicle.sim_number"></el-input>
                                                </el-form-item> -->

                                                <!-- <el-form-item label="Driver License">
                                                    <el-input v-model="vehicle.license"></el-input>
                                                </el-form-item> -->

                                            </el-col>

                                            <el-col :span="12">

                                                
                                                <div class="form-field__title" >VEHICLE ALERTS</div>

                                                <el-form-item label="Ignition Alert">
                                                    <input class="tgl tgl-light" id="cb1" type="checkbox" v-model="vehicle.acc_alert"/>
                                                    <label class="tgl-btn" for="cb1" style="margin-top:5px"></label>
                                                </el-form-item>

                                                
                                            </el-col>
                                        </el-row>

                                        <el-form-item style="margin-top:3rem;">
                                            <el-button type="primary" @click="save">Save</el-button>
                                            <el-button @click="cancel">Cancel</el-button>
                                        </el-form-item>
                                    </el-form>
                                


                        </el-tab-pane>
                        <el-tab-pane>
                            <span slot="label"><i class="el-icon-document"></i> Documents </span>

                            <ul class="document__list">
                                <li @click="showDocAdd('rc')" data-type="rc" v-bind:class="{ exists: existingDocsFlag.rc }">
                                    <i class="el-icon-circle-plus" v-bind:class="{ exists: existingDocsFlag.rc }"></i> Registration Certificate
                                    <div v-if="existingDocsFlag.rc">
                                        <i class="el-icon-date"></i>
                                        <span>{{ existingDocsFlag.rc.incorporated_on }}</span> - 
                                        <span>{{ existingDocsFlag.rc.expiration_date }}</span>
                                    </div>
                                </li>

                                <li @click="showDocAdd('ip')" data-type="ip" v-bind:class="{ exists: existingDocsFlag.ip }">
                                    <i class="el-icon-circle-plus" v-bind:class="{ exists: existingDocsFlag.ip }"></i> Insurance Policy
                                    <div v-if="existingDocsFlag.ip">
                                        <i class="el-icon-date"></i>
                                        <span>{{ existingDocsFlag.ip.incorporated_on }}</span> - 
                                        <span>{{ existingDocsFlag.ip.expiration_date }}</span>
                                    </div>
                                </li>

                                <li @click="showDocAdd('pc')" data-type="pc" v-bind:class="{ exists: existingDocsFlag.pc }">
                                    <i class="el-icon-circle-plus" v-bind:class="{ exists: existingDocsFlag.pc }"></i> Pollution Certificate
                                    <div v-if="existingDocsFlag.pc">
                                        <i class="el-icon-date"></i>
                                        <span>{{ existingDocsFlag.pc.incorporated_on }}</span> - 
                                        <span>{{ existingDocsFlag.pc.expiration_date }}</span>
                                    </div>
                                </li>

                                <li @click="showDocAdd('gps-wc')" data-type="gps-wc" v-bind:class="{ exists: existingDocsFlag[ 'gps-wc' ] }">
                                    <i class="el-icon-circle-plus" v-bind:class="{ exists: existingDocsFlag['gps-wc'] }"></i> GPS Warranty Card
                                    <div v-if="existingDocsFlag['gps-wc']">
                                        <i class="el-icon-date"></i>
                                        <span>{{ existingDocsFlag['gps-wc'].incorporated_on }}</span> - 
                                        <span>{{ existingDocsFlag['gps-wc'].expiration_date }}</span>
                                    </div>
                                </li>

                                <li @click="showDocAdd('cf')" data-type="cf" v-bind:class="{ exists: existingDocsFlag.cf }">
                                    <i class="el-icon-circle-plus" v-bind:class="{ exists: existingDocsFlag.cf }"></i> Certificate of Fitness
                                    <div v-if="existingDocsFlag.cf">
                                        <i class="el-icon-date"></i>
                                        <span>{{ existingDocsFlag.cf.incorporated_on }}</span> - 
                                        <span>{{ existingDocsFlag.cf.expiration_date }}</span>
                                    </div>
                                </li>

                                <li @click="showDocAdd('aip')" data-type="aip" v-bind:class="{ exists: existingDocsFlag.aip }">
                                    <i class="el-icon-circle-plus" v-bind:class="{ exists: existingDocsFlag.aip }"></i> All India Permit
                                    <div v-if="existingDocsFlag.aip">
                                        <i class="el-icon-date"></i>
                                        <span>{{ existingDocsFlag.aip.incorporated_on }}</span> - 
                                        <span>{{ existingDocsFlag.aip.expiration_date }}</span>
                                    </div>
                                </li>

                                <li @click="showDocAdd('tt')" data-type="tt" v-bind:class="{ exists: existingDocsFlag.tt }">
                                    <i class="el-icon-circle-plus" v-bind:class="{ exists: existingDocsFlag.tt }"></i> Tax Token
                                    <div v-if="existingDocsFlag.tt">
                                        <i class="el-icon-date"></i>
                                        <span>{{ existingDocsFlag.tt.incorporated_on }}</span> - 
                                        <span>{{ existingDocsFlag.tt.expiration_date }}</span>
                                    </div>
                                </li>

                                <li @click="showDocAdd('sp')" data-type="sp" v-bind:class="{ exists: existingDocsFlag.sp }">
                                    <i class="el-icon-circle-plus" v-bind:class="{ exists: existingDocsFlag.sp }"></i> State Permit
                                    <div v-if="existingDocsFlag.sp">
                                        <i class="el-icon-date"></i>
                                        <span>{{ existingDocsFlag.sp.incorporated_on }}</span> - 
                                        <span>{{ existingDocsFlag.sp.expiration_date }}</span>
                                    </div>
                                </li>

                                <li @click="showDocAdd('fc')" data-type="fc" v-bind:class="{ exists: existingDocsFlag.fc }">
                                    <i class="el-icon-circle-plus" v-bind:class="{ exists: existingDocsFlag.fc }"></i> Fastag Certificate
                                    <div v-if="existingDocsFlag.fc">
                                        <i class="el-icon-date"></i>
                                        <span>{{ existingDocsFlag.fc.incorporated_on }}</span> - 
                                        <span>{{ existingDocsFlag.fc.expiration_date }}</span>
                                    </div>
                                </li>

                                <li @click="showDocAdd('others')" data-type="others" v-bind:class="{ exists: existingDocsFlag.others }">
                                    <i class="el-icon-circle-plus" v-bind:class="{ exists: existingDocsFlag.others }"></i> Others
                                    <div v-if="existingDocsFlag.others">
                                        <i class="el-icon-date"></i>
                                        <span>{{ existingDocsFlag.others.incorporated_on }}</span> - 
                                        <span>{{ existingDocsFlag.others.expiration_date }}</span>
                                    </div>
                                </li>
                            </ul>
                        </el-tab-pane>
                    </el-tabs>
                </div>


                
                    
                <el-dialog
                    :title="documentTitle"
                    :visible.sync="dialogVisible"
                    width="50%"
                    top="60px"
                    bottom="0px"
                    class="panel">

                    <el-form ref="vehicleDocForm" :model="doc" label-width="200px" v-if="!currentDocument">
                        <div v-if="docType==='rc'">
                            <el-form-item label="Expiration Date">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.expiration_date"></el-input> -->
                                <el-date-picker
                                    v-model="doc.expiration_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Incorporated on">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.incorporated_on"></el-input> -->
                                <el-date-picker
                                    v-model="doc.incorporated_on"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Chasis Number">
                                <el-input class="vehicle-input w400" v-model="doc.chasis_no"></el-input>
                            </el-form-item>

                            <el-form-item label="Registration Number">
                                <el-input class="vehicle-input w400" v-model="doc.registration_no"></el-input>
                            </el-form-item>

                            <el-form-item label="Notes">
                                <el-input class="vehicle-input w400" v-model="doc.notes"></el-input>
                            </el-form-item>
                            
                        </div>


                        <div v-if="docType==='ip'">
                            <el-form-item label="Incorporated on">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.incorporated_on"></el-input> -->
                                <el-date-picker
                                    v-model="doc.incorporated_on"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Expiration Date">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.expiration_date"></el-input> -->
                                <el-date-picker
                                    v-model="doc.expiration_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Amount">
                                <el-input class="vehicle-input w400" v-model="doc.amount"></el-input>
                            </el-form-item>

                            <el-form-item label="Insurance Company">
                                <el-input class="vehicle-input w400" v-model="doc.insurance_company"></el-input>
                            </el-form-item>

                            <el-form-item label="Policy Number">
                                <el-input class="vehicle-input w400" v-model="doc.policy_number"></el-input>
                            </el-form-item>

                            <el-form-item label="Notes">
                                <el-input class="vehicle-input w400" v-model="doc.notes"></el-input>
                            </el-form-item>
                            
                        </div>


                        <div v-if="docType==='pc'">

                            <el-form-item label="Incorporated on">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.incorporated_on"></el-input> -->
                                <el-date-picker
                                    v-model="doc.incorporated_on"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Expiration Date">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.expiration_date"></el-input> -->
                                <el-date-picker
                                    v-model="doc.expiration_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Notes">
                                <el-input class="vehicle-input w400" v-model="doc.notes"></el-input>
                            </el-form-item>
                            
                        </div>

                        <div v-if="docType==='gps-wc'">

                            <el-form-item label="Installation Date">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.incorporated_on"></el-input> -->
                                <el-date-picker
                                    v-model="doc.installation_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Renewal Date">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.expiration_date"></el-input> -->
                                <el-date-picker
                                    v-model="doc.renewal_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Sim Number">
                                <el-input class="vehicle-input w400" v-model="doc.sim_number"></el-input>
                            </el-form-item>

                            <el-form-item label="Expiration Date">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.expiration_date"></el-input> -->
                                <el-date-picker
                                    v-model="doc.expiration_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Notes">
                                <el-input class="vehicle-input w400" v-model="doc.notes"></el-input>
                            </el-form-item>
                            
                        </div>

                        <div v-if="docType==='cf'">

                            <el-form-item label="Incorporated on">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.incorporated_on"></el-input> -->
                                <el-date-picker
                                    v-model="doc.incorporated_on"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Expiration Date">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.expiration_date"></el-input> -->
                                <el-date-picker
                                    v-model="doc.expiration_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Reference NUmber">
                                <el-input class="vehicle-input w400" v-model="doc.reference_number"></el-input>
                            </el-form-item>

                            <el-form-item label="Notes">
                                <el-input class="vehicle-input w400" v-model="doc.notes"></el-input>
                            </el-form-item>
                            
                        </div>

                        <div v-if="docType==='aip'">

                            <el-form-item label="Incorporated on">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.incorporated_on"></el-input> -->
                                <el-date-picker
                                    v-model="doc.incorporated_on"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Expiration Date">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.expiration_date"></el-input> -->
                                <el-date-picker
                                    v-model="doc.expiration_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Reference Number">
                                <el-input class="vehicle-input w400" v-model="doc.reference_number"></el-input>
                            </el-form-item>

                            <el-form-item label="Notes">
                                <el-input class="vehicle-input w400" v-model="doc.notes"></el-input>
                            </el-form-item>
                            
                        </div>

                        <div v-if="docType==='tt'">

                            <el-form-item label="Incorporated on">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.incorporated_on"></el-input> -->
                                <el-date-picker
                                    v-model="doc.incorporated_on"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Expiration Date">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.expiration_date"></el-input> -->
                                <el-date-picker
                                    v-model="doc.expiration_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="TT Number">
                                <el-input class="vehicle-input w400" v-model="doc.tt_number"></el-input>
                            </el-form-item>

                            <el-form-item label="Notes">
                                <el-input class="vehicle-input w400" v-model="doc.notes"></el-input>
                            </el-form-item>
                            
                        </div>

                        <div v-if="docType==='sp'">

                            <el-form-item label="Vehicle Number">
                                <el-input class="vehicle-input w400" v-model="doc.vehicle_number"></el-input>
                            </el-form-item>

                            <el-form-item label="Incorporated on">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.incorporated_on"></el-input> -->
                                <el-date-picker
                                    v-model="doc.incorporated_on"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Amount">
                                <el-input class="vehicle-input w400" v-model="doc.amount"></el-input>
                            </el-form-item>

                            <el-form-item label="State">
                                <el-input class="vehicle-input w400" v-model="doc.state"></el-input>
                            </el-form-item>

                            <el-form-item label="Expiration Date">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.expiration_date"></el-input> -->
                                <el-date-picker
                                    v-model="doc.expiration_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Notes">
                                <el-input class="vehicle-input w400" v-model="doc.notes"></el-input>
                            </el-form-item>
                            
                        </div>

                        <div v-if="docType==='fc'">

                            <el-form-item label="Expiration Date">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.expiration_date"></el-input> -->
                                <el-date-picker
                                    v-model="doc.expiration_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Notes">
                                <el-input class="vehicle-input w400" v-model="doc.notes"></el-input>
                            </el-form-item>
                            
                        </div>


                        <div v-if="docType==='others'">

                            <el-form-item label="Name">
                                <el-input class="vehicle-input w400" v-model="doc.name"></el-input>
                            </el-form-item>

                            <el-form-item label="Incorporated on">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.incorporated_on"></el-input> -->
                                <el-date-picker
                                    v-model="doc.incorporated_on"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Expiration Date">
                                <!-- <el-input class="vehicle-input w400" v-model="doc.expiration_date"></el-input> -->
                                <el-date-picker
                                    v-model="doc.expiration_date"
                                    type="date"
                                    placeholder="Pick a day"
                                    :picker-options="pickerOptions">
                                </el-date-picker>
                            </el-form-item>

                            <el-form-item label="Description">
                                <el-input class="vehicle-input w400" v-model="doc.description"></el-input>
                            </el-form-item>

                            <el-form-item label="Notes">
                                <el-input class="vehicle-input w400" v-model="doc.notes"></el-input>
                            </el-form-item>
                            
                        </div>

                        <div>
                            <div v-if="!docLoaded && doc.file_ref_url" class="doc-image-loader">
                                <img class="loading-svg" src="/images/three-dots.svg">
                            </div>
                            <img :src="doc.file_ref_url" style="width:20%; margin:0 auto; display:flex; padding:10px;" @load="isDocumentImageLoaded">
                        </div>
                        <el-upload 
                            class="upload-demo"
                            :on-change="uploadDocuments"
                            :file-list="documentList"
                            :auto-upload="false"
                            accept="image/*,.pdf"
                            action="">
                            <el-button size="small" type="primary" v-if="doc.file_ref">Change</el-button>
                            <el-button size="small" type="primary" v-if="!doc.file_ref">Upload</el-button>
                            <div slot="tip" class="el-upload__tip">jpg/png/pdf files with a size less than 500kb</div>
                        </el-upload>
                    </el-form>

                    <span slot="footer" class="dialog-footer">
                        <el-button @click="handleDocSave('vehicleDocForm')" type="success">Save <i class="el-icon-check"></i></el-button>
                        <el-button @click="dialogVisible = false" type="danger">Cancel <i class="el-icon-close"></i></el-button>
                    </span>

                    <!-- <v-doc></v-doc> -->
                </el-dialog>
                    

            </el-col>

        </el-row>
        <!-- columns end -->

        <v-doc/>
    </div>
</template>

<script>
import firebase from '@/firebaseConfig.js'
import { mapGetters } from 'vuex'
import router from '@/router.js'
import doc from '@/modules/documents'
import { Notification } from 'element-ui'

var generatePushID = (function() {
    // Modeled after base64 web-safe chars, but ordered by ASCII.
    var PUSH_CHARS = '-0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ_abcdefghijklmnopqrstuvwxyz';

    // Timestamp of last push, used to prevent local collisions if you push twice in one ms.
    var lastPushTime = 0;

    // We generate 72-bits of randomness which get turned into 12 characters and appended to the
    // timestamp to prevent collisions with other clients.  We store the last characters we
    // generated because in the event of a collision, we'll use those same characters except
    // "incremented" by one.
    var lastRandChars = [];

    return function() {
        var now = new Date().getTime();
        var duplicateTime = (now === lastPushTime);
        lastPushTime = now;

        var timeStampChars = new Array(8);
        for (var i = 7; i >= 0; i--) {
            timeStampChars[i] = PUSH_CHARS.charAt(now % 64);
            // NOTE: Can't use << here because javascript will convert to int and lose the upper bits.
            now = Math.floor(now / 64);
        }
        if (now !== 0) throw new Error('We should have converted the entire timestamp.');

        var id = timeStampChars.join('');

        if (!duplicateTime) {
            for (i = 0; i < 12; i++) {
                lastRandChars[i] = Math.floor(Math.random() * 64);
            }
        } else {
            // If the timestamp hasn't changed since last push, use the same random number, except incremented by 1.
            for (i = 11; i >= 0 && lastRandChars[i] === 63; i--) {
                lastRandChars[i] = 0;
            }
            lastRandChars[i]++;
        }
        for (i = 0; i < 12; i++) {
            id += PUSH_CHARS.charAt(lastRandChars[i]);
        }
        if (id.length != 20) throw new Error('Length should be 20.');

        return id;
    };
})();

const documentsMap = {
    'rc': 'Registration Certificate',
    'ip': 'Insurance Policy',
    'pc': 'Pollution Certificate',
    'gps-wc': 'GPS Warranty Card',
    'cf': 'Certificate of Fitness',
    'aip': 'All India Permit',
    'tt': 'Tax Token',
    'sp': 'State Permit',
    'fc': 'Fastag Certificate',
    'others': 'Others'
}

export default {
    name: 'revenueAdd',
    components: {
        'v-doc': doc
    },
    data() {
        return {
            dialogVisible: false,
            docType: '',
            docLoaded: false,
            doc: {},
            documentList: [],
            existingDocs: [],
            existingDocsFlag: {
                rc: false,
                ip: false,
                pc: false,
                "gps-wc": false,
                cf: false,
                aip: false,
                tt: false,
                sp: false,
                fc: false,
                others: false,

            },
            currentDocument: null,
            documentTitle: 'Registration Certificate',
            fullscreenLoading: false,
            pickerOptions: {
                shortcuts: [{
                    text: 'Today',
                    onClick(picker) {
                        picker.$emit('pick', new Date());
                    }
                }, {
                    text: 'Yesterday',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        picker.$emit('pick', date);
                    }
                }, {
                    text: 'A week ago',
                    onClick(picker) {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', date);
                    }
                }]
            },
        }
    },
    computed: {
        ...mapGetters({
                vehicles: '$_vehicles/vehicles',
                vehicle: '$_vehicles/vehicle'
        })
    },
    methods: {
        isDocumentImageLoaded() {
            this.docLoaded = true
        },
        save() {
            const id = this.$route.params.id
            this.fullscreenLoading = true
            this.vehicle.acc_alert = this.vehicle.acc_alert ? 3 : 0
            
            this.$store.dispatch('$_vehicles/updateVehicle', {
                id     : id,
                vehicle: this.vehicle
            })
            
            this.fullscreenLoading = false
            // router.push({ name: 'vehicles' })
        },
        cancel() {
            router.push({ name: 'vehicles' })
        },
        findDocByType: function() {
            var self       = this
            var storageRef = firebase.app.storage().ref()
            var docFound   = false

            if (this.existingDocs.length > 0) {
                
                this.existingDocs.forEach(function(doc) {
                    if (doc.doc_type === self.docType) {
                        docFound = true
                        // convert date to localeString
                        for(var key in doc) {
                            if (doc.hasOwnProperty(key)) {
                                if (doc[ key ] && doc[ key ].toDate) {
                                    doc[ key ] = doc[ key ].toDate().toLocaleString()
                                }
                            }
                        }
                        self.docLoaded = false
                        self.dialogVisible = true
                        storageRef.child(doc.file_ref).getDownloadURL().then((url) => {
                            doc.file_ref_url = url
                            self.doc         = doc
                            
                        })

                    }
                })

                if (!docFound) {
                    this.dialogVisible = true    
                }

            } else {
                this.dialogVisible = true

            }
            
        },
        showDocAdd(docType) {
            this.doc           = {}
            this.docType       = docType
            this.documentTitle = documentsMap[ docType ]
            this.findDocByType()
        },
        handleDocSave(formName) {
            this.uploadImageToFirestorage(formName)
        },
        saveDocument(file_ref) {
            var self = this
            this.doc.vehicle_id = this.$route.params.id
            this.doc.file_ref   = file_ref
            this.doc.doc_type   = this.docType
            this.$store.dispatch('$_documents/addDocument', this.doc)
                .then(function(res) {
                    Notification.success({
                        title  : 'Success',
                        message: 'Document added successfully',
                        offset : 100
                    })

                    self.existingDocsFlag[ self.doc.doc_type ] = true
                    self.dialogVisible = false
                })
        },
        uploadDocuments(files) {
            this.documentList = [files]
        },
        uploadImageToFirestorage(formName) {
            var storageRef          = firebase.app.storage().ref()
            var uploadTaskRemaining = this.documentList.length
            var self                = this
            
            this.documentList.forEach(function (file) {
                if (file.hasOwnProperty( 'status' ) && file.status === 'success') {
                    uploadTaskRemaining--
                    if (uploadTaskRemaining === 0) {
                        self.fullscreenLoading = false
                        // self.handleSave(formName)
                    }
                    return
                }

                var fileRef    = 'documents/'+ generatePushID() + '.' + file.name.split('.').pop()
                var imagesRef  = storageRef.child(fileRef);
                var uploadTask = imagesRef.put(file.raw, {
                    name: file.name
                })

                file.ref = fileRef

                uploadTask.on('state_changed', function(snapshot) {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
                },
                function(error) {
                    // Handle unsuccessful uploads
                    console.log('error uploading file:', error);
                },
                function() {
                    console.log('upload success');
                    // store the document now
                    self.saveDocument(fileRef)
                });
            })
        }
    },
    created() {
        const id = this.$route.params.id
        if (!id) {
            return
        }

        var self = this
        // https://stackoverflow.com/questions/46643530/how-to-perform-a-query-in-firebases-cloud-firestore
        // https://firebase.google.com/docs/firestore/query-data/queries
        firebase.documents.where('vehicle_id', '==', id)
            .get()
            .then(function(results) {
                if (results.empty) {
                    console.log('No documents found');
                } else {
                    // go through all results
                    results.forEach(function(doc) {
                        var data = doc.data()
                        self.existingDocs.push(data)
                        // self.existingDocsFlag[ data.doc_type ] = true
                        // new Date(existingDocsFlag.rc.expiration_date).toDateString()
                        if (data.expiration_date) {
                            // console.log('data.expiration_date: ', data.expiration_date);
                            if (data.expiration_date.toMillis) {
                                data.expiration_date = new Date(data.expiration_date.toMillis()).toDateString()
                                data.expiration_date = data.expiration_date.slice(4)
                            }
                            
                            
                        }

                        if (data.incorporated_on) {
                            // console.log('data.incorporated_on.toMillis: ', data.incorporated_on);
                            if (data.incorporated_on.toMillis) {
                                data.incorporated_on = new Date(data.incorporated_on.toMillis()).toDateString()
                                data.incorporated_on = data.incorporated_on.slice(4)
                            }
                            
                        }

                        self.existingDocsFlag[ data.doc_type ] = data
                    })

                    console.log('Existing documents:: ', self.existingDocsFlag);
                }
            })
            .catch(function(error) {
                console.log('Error getting documents: ', error);
            })
    }
}
</script>

<style lang="scss">

.vehicle__head {
    padding:1rem 2rem;
}

.el-form-item__label {
    font-weight: bold;
}

.vehicle-edit {
    padding: 0 2rem;
    background-color: #FFF;
    // box-shadow: 0 0 12px #EFEFEF;
    border-radius: 7px;
    width:100%;

    .el-tabs__content {
        padding:2rem !important;
    }
}

.form-field__title {
    margin-bottom:1rem;
    font-weight: bold;
    font-size:1rem;
}

.vehicle-input {
    &.w300 {
        width:300px !important;
    }

    &.w400 {
        width:400px !important;
    }
    
}

.el-tabs__item {
    font-size:1.1rem !important;
}

.document-nav {
    .el-tabs__item {
        padding:.5rem;
        height:unset;
    }
}

.document__list {
    margin:0;
    padding:0;
    li {
        padding:3rem;
        cursor:pointer;
        border:1px solid #ddd;
        margin-bottom:1rem;
        width:50%;
        box-shadow:0 0 10px rgba(200, 200, 200, 0.3);
        border-left:7px solid #DDD;

        div {
            margin-top:1rem;
        }

        &:hover {
            color:#F80;
            transition:color .3s;
        }

        i.exists {
            color:rgb(28, 179, 20);
        }

        i.el-icon-date {
            margin-right:5px;
        }

        &.exists {
            border-left:7px solid #9FD6AE;
        }
    }
}


.tgl {
	display: none;
  
	// add default box-sizing for this scope
	&,
  &:after,
  &:before,
	& *,
  & *:after,
  & *:before,
	& + .tgl-btn {
		box-sizing: border-box;
		&::selection {
			background: none;
		}
	}
  
	+ .tgl-btn {
		outline: 0;
		display: block;
		width: 4em;
		height: 2em;
		position: relative;
		cursor: pointer;
    user-select: none;
		&:after,
    &:before {
			position: relative;
			display: block;
			content: "";
			width: 50%;
			height: 100%;
		}
    
		&:after {
			left: 0;
		}
    
		&:before {
			display: none;
		}
	}
  
	&:checked + .tgl-btn:after {
		left: 50%;
	}
}

// themes
.tgl-light {
	+ .tgl-btn {
		background: #f0f0f0;
		border-radius: 2em;
		padding: 2px;
		transition: all .4s ease;
		&:after {
			border-radius: 50%;
			background: #fff;
			transition: all .2s ease;
		}
	}
  
	&:checked + .tgl-btn {
		background: #9FD6AE;
	}
}

.doc-image-loader {
    display: flex;
    justify-content: center;
}
</style>
