import api from '../_api'
import { Notification } from 'element-ui'
import router from '@/router.js'

const fetchVehicles = (context, id) => {
    return api.fetchVehicles()
        .then(function (response) {
            context.commit('VEHICLES_FETCHED', response.data.fleet)
            console.log('response.data: ', response.data);
            if (typeof id !== 'undefined') {
                context.commit('VEHICLE_FETCHED_BY_ID', id)
            }
        })
        .catch(function(err) {
            console.log(err);
        })
}

// not used as of now
const fetchVehicleById = (context, id) => {
    context.commit('VEHICLE_FETCHED_BY_ID', id)
}

const updateVehicle = (context, data) => {
    return api.updateVehicleById(data.id, data.vehicle)
                .then(function (response) {
                    context.commit('VEHICLE_UPDATED', data.vehicle)
                    Notification.success({
                        title: 'Success',
                        message: 'Vehicle updated successfully',
                        offset: 100
                    })
                    
                    router.push({ name: 'vehicles' })
                })
                .catch(function(err) {
                    console.log(err);
                    Notification.error({
                        title: 'Error',
                        message: 'Error updating vehicle',
                        offset: 100
                    })
                })
}

export default {
    fetchVehicles,
    fetchVehicleById,
    updateVehicle
}

