<template>
    <MediaModule/>
</template>


<script>
import MediaModule from '@/modules/media'

export default {
    name: 'Media',
    components: {
        MediaModule
    }
}
</script>
