<template>
    <div>
        <div class="revenue-table__head">
            <h1>Vehicles</h1>
            
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }">Home</el-breadcrumb-item>
                <el-breadcrumb-item>Vehicles</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card class="box-card">
            <el-table 
                :data="vehicles" 
                :row-class-name="tableRowClassName" class="revenue-table">
                    
                <!-- <el-table-column type="selection" width="55">
                </el-table-column> -->

                <el-table-column
                    sortable
                    prop="status"
                    label="Status">
                    <template slot-scope="scope">
                        <span v-if="scope.row.status==0">Engine off</span>
                        <span v-if="scope.row.status==1">Engine on</span>
                        <span v-if="scope.row.status==2">Not responding</span>
                        <span v-if="scope.row.status==3">Idle</span>
                    </template>
                </el-table-column>

                <el-table-column
                    sortable
                    prop="_id"
                    label="IMEI">
                </el-table-column>

                <el-table-column
                    sortable
                    prop="reg_no"
                    label="Registration Number">
                </el-table-column>

                <el-table-column
                    sortable
                    prop="d_name"
                    label="Driver Name">
                </el-table-column>
                
                <!-- <el-table-columne
                    prop="sim_number"
                    sortable
                    label="Driver Number">
                </el-table-column> -->
                
                <!-- <el-table-column
                    prop="type"
                    sortable
                    label="Driver License">
                </el-table-column> -->
                
                <el-table-column
                    prop="v_type"
                    sortable
                    label="Vehicle Type">
                </el-table-column>

                <!-- <el-table-column
                    prop="theft_mode"
                    sortable
                    label="Anti Theft">

                    <template slot-scope="scope">
                        <el-switch
                            v-model="scope.row.theft_mode"
                            active-text="On"
                            inactive-text="Off">
                        </el-switch>
                    </template>
                </el-table-column> -->

                <el-table-column >
                    <template slot-scope="scope">
                        <el-button type="primary"
                            size="mini"
                            @click="handleEdit(scope.$index, scope.row)">
                                <i class="el-icon-edit"></i> Edit</el-button>

                    </template>
                </el-table-column>
            </el-table>
        </el-card>
        <!-- <div class="block revenue-table__pagination">
            <el-pagination layout="prev, pager, next" :total="50">
            </el-pagination>
        </div> -->


    </div>
</template>


<script>
    import router from '@/router.js'

    export default {
        name: 'VehicleList',
        data() {
            return {
                searchByType: 'Vehicle',
                searchByText: '',
                multipleSelection: [],
                dialogVisible: false,
                vehicle: {}
            }
        },

        props: {
            vehicles: Array
        },
        methods: {
            handleClose() {

            },
            tableRowClassName({row, rowIndex}) {
                if (row.category === 'Service') {
                    return 'warning-row';
                } else if (row.category === 'Fuel') {
                    return 'success-row';
                }

                return '';
            },
            handleEdit(index, transaction) {
                router.push({ name: 'vehiclesEdit', params: { id: transaction._id } })

            },
            // status : 0 means engine is off, 1 means engine is onn ! 2 means not responding ! 3 means Idle
            handleView(index, vehicle) {
                var vehicleData = JSON.parse(JSON.stringify( vehicle ))
                console.log('===> ', vehicleData);
                vehicleData.status = {
                    0: 'Engine off',
                    1: 'Engine on',
                    2: 'Not responding',
                    3: 'Idle'
                }[ vehicleData.acc_alert ]
                
                vehicleData.acc_alert = {
                    0: 'Off',
                    3: 'On'
                }[ vehicleData.acc_alert ]

                if (vehicleData.hasOwnProperty('lkt')) {
                    vehicleData.lkt = new Date(vehicleData.lkt).toDateString()
                }
                this.dialogVisible = true
                this.vehicle       = vehicleData
            },
            // handleDelete(index, transaction) {
            //     this.$store.dispatch('$_vehicles/deletevehicles', transaction.id)
            //     this.dialogVisible = false
            // }
        },
        created() {

        },
        mounted() {
            
        }
    }
</script>


<style lang="scss">
.vehicle__type-icon {
    width: 50px;
    margin:0 auto;
}

.details__title {
    font-size:1.4rem;
    font-weight: bold;
    border-bottom:2px solid #f80;
    margin-bottom:1rem;
}

.details__title-sub {
    font-size:1.3rem;
    font-weight: normal;
}

.vdetail__field-label {
    width:120px !important;
    display: inline-block;
    font-weight: bold;
}

.detail__status {
    text-align:center;
    font-size:1.1rem;
}

.detail__title {
    font-size:1.1rem;
    margin-top:2rem;
    border-bottom:1px solid #EFEFEF;
    font-weight: bold;
}

.el-dialog__title {
    text-transform: uppercase;
    font-weight: bold;
    font-size:1.4rem !important;
}

.vehicle__info {
    .el-tabs__item {
        font-size:1.1rem;
    }
}

</style>
