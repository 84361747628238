import $$ from '@/configs/const.js'
import firebase from '@/firebaseConfig.js'

/**
 * 
 * https://firebase.google.com/docs/firestore/manage-data/add-data
 */
const addDocument = function(newDocument) {
    
    return new Promise((resolve, reject) => {
        newDocument.orgid = $$.uid
        console.log('newDocument:: ', newDocument);
        firebase.documents.add(newDocument)
            .then(function( docRef ) {
                newDocument.id = docRef.id

                if (newDocument.date && newDocument.date.toDate) {
                    newDocument.date = newDocument.date.toDate().toLocaleString()
                }

                resolve(newDocument)

            })
            .catch(function(error) {
                console.error("Error writing document: ", error);
                reject(error)
            });
        })
    }


export default {
    addDocument,
}