<template>
    <div>
        <el-row >
            <el-col :span="24" class="is-fixed navbar-top">
                <NavBar></NavBar>
            </el-col>
        </el-row>
        
        <el-row class="content-wrap">
            <el-col :sm="4" :xs="24" class="is-fixed" style="z-index:99;">
                <AsideBar/>
            </el-col>

            <el-col :sm="20" :xs="24" class="d-content-right">
                <!-- <BreadCrumb/> -->
                    
                <el-row>
                    <el-col :span="24">
                        <slot />
                    </el-col>
                </el-row>   
            </el-col>
        </el-row>
    </div>
</template>


<script>

import NavBar from '@/components/NavBar/NavBar.vue'
import AsideBar from '@/components/AsideBar/AsideBar.vue'
import BreadCrumb from '@/components/BreadCrumb/BreadCrumb.vue'

export default {
    components: {
        NavBar,
        AsideBar,
        BreadCrumb
    }
}
</script>
