import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore'
import config from './configs'

const app       = firebase.initializeApp(config)
const firestore = firebase.firestore();

firestore.settings({timestampsInSnapshots: true});

const api          = app.firestore()
const transactions = api.collection('transactions')
const documents    = api.collection('documents')
const auth         = app.auth()

export default {
    api,
    app,
    auth,
    transactions,
    documents,
}