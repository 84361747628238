import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/'
import VueFire from 'vuefire'
import axios from 'axios'

import vars from '@/configs/const.js'

// Vuejs
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import Normalize from 'normalize.css'
import style from './assets/scss/style.scss'


// Layouts
// imported globally 
import AdminLayout from './layouts/Admin.vue'
import AuthenticationLayout from './layouts/Authentication.vue'
import firebase from './firebaseConfig.js'

Vue.filter('formatDate', function (value) {
    if (!value) return ''
    
    value = value.toString()
    return value.split( ',' )[0]
})

Vue.component('admin-layout', AdminLayout)
Vue.component('authentication-layout', AuthenticationLayout)

Vue.config.productionTip = false
Vue.use(VueFire)
Vue.use(ElementUI, { locale })

Vue.prototype.$firebase = firebase

let app = null
  
window.addEventListener('message', function(e) {
    if (e.data === 'vue__done') {
        window.location.href='/'
    }
    
})

firebase.auth.onAuthStateChanged((user) => {
    if (user) {
        firebase.auth.currentUser.getIdToken().then(function(token) {
            
            firebase.app.auth( token )
            firebase.token = token

            const axiosInstance = axios.create({
                baseURL: 'https://zuapi.zuaxis.com/',
                headers: {'Authorization': 'Bearer ' + token}
            })

            vars.http = axiosInstance
            
            axiosInstance.get('/customer/handshake')
                .then(function (response) {
                    var info = response.data.info
                    
                    self.name     = info.name
                    self.email    = info.email
                    self.address  = info.address
                    self.category = {
                        1: 'standard',
                        2: 'business',
                        3: 'premium'
                    }[info.category]
                    
                    vars.uid = info._id

                    Vue.prototype.$http = axiosInstance
                    if (!app) {
                        app = new Vue({
                            router,
                            store,
                            render: h => h(App)
                        }).$mount("#app")
                    }

                })
                .catch(function(err) {
                    console.log(err);
                })
        })
    } else {
        if (!app) {
            app = new Vue({
                router,
                store,
                render: h => h(App)
            }).$mount( '#app' )
        }
    }

})
