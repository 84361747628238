<template>
    <div>
        <revenueAdd/>
    </div>
  
</template>

<script>
import { mapGetters } from 'vuex'
import store from './_store'
import RevenueAdd from './_components/RevenueAdd'

export default {
    name: 'RevenueAddModule',
    components: {
        RevenueAdd,
    },
    computed: {
        ...mapGetters({
            revenues: '$_revenue/revenues'
        })
    },
    created() {
        const STORE_KEY = '$_revenue'
        if (!(STORE_KEY in this.$store._modules.root._children)) {
            this.$store.registerModule(STORE_KEY, store);
        }
    },
    mounted() {
        // action call "addRevenue" method
        this.$store.dispatch('$_revenue/getRevenues');
    }

}
</script>
