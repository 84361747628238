<template>
<el-row class="breadcrumb">
    <!-- <el-col :span="18">
        <div class="breadcrumb-nav">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">homepage</el-breadcrumb-item>
                <el-breadcrumb-item>promotion management</el-breadcrumb-item>
                <el-breadcrumb-item>promotion list</el-breadcrumb-item>
                <el-breadcrumb-item>promotion detail</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
    </el-col> -->

    <el-col :span="6">
    </el-col>
</el-row>
</template>



<script>
export default {
    name: 'BreadCrumb'
}
</script>



<style>
    .breadcrumb {
        padding:1rem 2rem;
    }
</style>
