<template>
    <div>
        <iframe style="position:absolute; margin-top: -110px; border-left:0;" :src="iframe_src"></iframe>
    </div>
</template>


<script>

import vars from '@/configs/const.js'

export default {
    name: 'Iframe',
    data() {
        return {
            iframe_src: '/'
        }
    },
    mounted() {
        var iframe              = document.querySelector('iframe')
        var topOffset           = document.querySelector('#topNavBar').getBoundingClientRect().height
        
        iframe.style.width  = (window.innerWidth - document.querySelector('#leftSideBar').getBoundingClientRect().width) + 'px'
        iframe.style.height = (window.innerHeight - (110 - topOffset - 35)) + 'px'
        iframe.style.top    = topOffset + 'px'
        
        var pathname = location.pathname
        if (pathname === '/maps') {
            pathname = '/'
        }
        
        this.iframe_src = vars.iframe_src + pathname
        
    },
    methods: {
        changeIframeSrc(to) {
            var pathname = location.pathname
            console.log('>>>> ', pathname);
            if (pathname === '/maps') {
                pathname = ''
            }

            this.iframe_src = vars.iframe_src + pathname
        }
    },
    watch: {
        '$route' (to, from) {
            this.changeIframeSrc(to.fullPath)
        }
    }
}
</script>
