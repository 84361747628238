<template>
    <!-- <el-card class="box-card revenue-wrap "> -->
        <div>
            <RevenueContainer/>

        </div>
    <!-- </el-card> -->
</template>


<script>
import RevenueContainer from '@/modules/revenue'

export default {
    name: 'Revenue',
    components: {
        RevenueContainer
    }
}
</script>


<style>
.revenue-wrap {
    margin:2rem;
    /* background-color:#FFF; */
    padding:1rem;
    /* box-shadow: 0 2px 2px 0 rgba(0,0,0,.1); */
}
</style>
