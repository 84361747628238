<template>
    <div class="login-container">
        <div class="login-container__wrap">
            <div class="left-wrap">
                <img src="/images/zuaxis_logo.png">
            </div>

            <div class="right-wrap">
                <!-- <form> -->
                    <div class="field">
                        <h3>Member Login</h3>
                    </div>
                    <div class="field">
                        <input type="text" v-model="email" placeholder="email">
                    </div>

                    <div class="field">
                        <input type="password" v-model="password" placeholder="password">
                    </div>

                    <div class="field">
                        <button style="width:125px" @click="login" v-if="iframe_loaded">Connect 
                            <svg v-if="!show_loading" class="feather feather-log-in sc-dnqmqq jxshSx" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" aria-hidden="true" data-reactid="721"><path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4"></path><polyline points="10 17 15 12 10 7"></polyline><line x1="15" y1="12" x2="3" y2="12"></line></svg>
                            <div class="loader" title="0" v-if="show_loading">
                                <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                width="24" height="24" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
                                <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
                                    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
                                    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
                                <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
                                    C22.32,8.481,24.301,9.057,26.013,10.047z">
                                    <animateTransform attributeType="xml"
                                    attributeName="transform"
                                    type="rotate"
                                    from="0 20 20"
                                    to="360 20 20"
                                    dur="0.5s"
                                    repeatCount="indefinite"/>
                                    </path>
                                </svg>
                            </div>
                        </button>
                    </div>

                    <div class="field login-error" v-if="error">
                        * Password and email id mismatch. Please input the correct login details.
                    </div>
                <!-- </form> -->
            </div>
        </div>

        <iframe :src="iframe_src" style="top:-999999px; position:fixed" @load="handleIframeLoad"/>
    </div>
</template>


<script>
import firebase from 'firebase'
import $ from '@/configs/const'

export default {
    name: 'Login',
    data() {
        return {
            error: false,
            email: '',
            password: '',
            iframe_src: $.iframe_src+ '/login',
            show_loading: false,
            iframe_loaded: false
        }
    },
    methods: {
        login(e) {
            e.preventDefault()
            var self = this
            this.show_loading = true
            firebase.auth().signInWithEmailAndPassword(this.email, this.password)
                .then(function(user) {
                    // self.$router.replace('/')

                    var iframe = document.querySelector('iframe')
                    iframe.contentWindow.postMessage( btoa(JSON.stringify({ _u: self.email, _p: self.password })), $.iframe_src )

                    

                }, function(err) {
                    console.log('err', err);
                    self.error = true
                })
            
        },
        handleIframeLoad() {
            console.log('in iframe');
            this.iframe_loaded = true
        }
    },
    created() {
        var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
        if (isSafari) {
            alert('We do not support all the features in Safari web browser. Please use latest version of chrome or firefox.')
        }
    }
}
</script>

<style lang="scss">
.login-container {
    display: flex;
    align-items:center;
    justify-content: center;
    background: #9053c7;
    background: -webkit-linear-gradient(-135deg, #c850c0, #4158d0);
    background: -o-linear-gradient(-135deg, #c850c0, #4158d0);
    background: -moz-linear-gradient(-135deg, #c850c0, #4158d0);
    background: linear-gradient(-135deg, #c850c0, #4158d0);
    height:100vh;
}

.login-container__wrap {
    border-radius:10px;
    box-shadow:0 0 12px #EFEFEF;
    padding:2rem;
    background-color:#FFF;
    display: flex;
    height:40%;

    .field {
        margin-bottom:1rem;
    }

    input {
        height: 30px;
        border-radius: 2rem;
        background: #e6e6e6;
        border: 1px solid #EFEFEF;
        line-height: 1.5;
        padding: .3rem 1rem;
        outline: none;
        width:300px;
    }
}

.login-error {
    color:#F00;
    font-weight: bold;
}

.left-wrap {
    
    img {
        width:300px;
    }
}

.right-wrap {
    flex-direction: column;

    button {
        display: flex;
        padding: .3rem 1rem;
        background-color: #57b846;
        color: #FFF;
        line-height: 1.5;
        border-radius: 2rem;
        font-weight: bold;
        cursor: pointer;
        width: 100%;
        height: 40px;
        border: none;
        box-shadow: 0 0 10px #DDD;
        transition: background .3s;
        outline:none;
        align-items: center;

        &:hover {
            background-color:#333;
        }

        svg {
            margin-left:.5rem;
        }
    }
}

.left-wrap, .right-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader {
    svg path,
    svg rect{
    fill: #FFF;
    }
}
</style>
